// Entyities einer "Kind" Paginated als Liste darstellen

import { useQuery } from '@apollo/client'
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { assertIsString } from 'assertate'
import pThrottle from 'p-throttle'
import React, { useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import { useAddPanel } from '../hooks/useAddPanel'
import { useDebounce } from '../hooks/useDebounce'
import { useBulkEditPanel } from './EntityBulkEdit'
import { IKindListeProps } from './KindListe'
import { KTTheEntityTable, ktUpdateListQueryOptions } from './KindListeHelpers'

// max 5 calls in 5 seconds
const throttle = pThrottle({
  limit: 2,
  interval: 700,
})

export interface IKindListePaginatedProps extends IKindListeProps {
  initialLimit?: number // Anzahl der anfangs angezeigten Datensätzen
  bulkEditKeyField?: string // bulkEditor aktivieren
}
/**
 * Zeigt eine Liste mit Entities.
 * Das ist die default Seite für zB den "Kunden" Bereich.
 * Steht in sehr engem Zusammenhang mit <SchemaTable>,
 * siehe auch dort die Dokumentation.
 */
export const KindListePaginated = (props: IKindListePaginatedProps) => {
  const [urlLimit, setUrlLimit] = useQueryParam('limit', NumberParam)
  const [limit, setLimit] = useState(urlLimit || props.initialLimit || 100)
  const [lastCursor, setLastCursor] = useState('')
  const listQueryOptions = ktUpdateListQueryOptions(props)
  const [createItem, createPanel, createButton] = useAddPanel({
    ...props,
    listQueryOptions,
  })
  const [bulkEditItem, bulkEditPanel] = useBulkEditPanel({
    schemaName: props.schemaName,
    listQuery: props.listQuery,
    listQueryOptions: props.listQueryOptions,
    editMutation: props.editMutation,
    createMutation: props.createMutation,
    keyField: props.bulkEditKeyField,
  })

  const { data, loading, error, fetchMore, networkStatus } = useQuery(props.listQuery, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    ...listQueryOptions,
    variables: {
      cursor: null,
      ...listQueryOptions.variables,
    },
  })
  const debouncedNetworkStatus = useDebounce(networkStatus, 250)
  const slowFetchMore = throttle((options) => fetchMore(options))

  const items: Array<ICommandBarItemProps> = [createItem]
  if (bulkEditItem) {
    items.push({ ...bulkEditItem, disabled: data?.nodes?.pageInfo?.hasNextPage })
  }

  // https://www.ag-grid.com/javascript-data-grid/immutable-data/
  let rowData
  if (data?.nodes?.edges) {
    rowData = data?.nodes?.edges.map((x) => x?.node)
  }
  // Wenn noch Daten vorhanden sind, und wir weniger, als unser Limit haben, nachladen.
  if (data?.nodes?.pageInfo?.hasNextPage && rowData.length < limit && !loading) {
    if (data.nodes.pageInfo.endCursor === lastCursor) {
      console.log('duplicate query', {
        variables: {
          ...listQueryOptions.variables,
          cursor: data.nodes.pageInfo.endCursor,
        },
      })
    } else {
      setLastCursor(data.nodes.pageInfo.endCursor)
      slowFetchMore({
        variables: {
          ...listQueryOptions.variables,
          cursor: data.nodes.pageInfo.endCursor,
        },
      })
    }
  }
  if (data?.nodes?.pageInfo?.hasNextPage) {
    const item: ICommandBarItemProps = {
      key: 'mehrLaden',
      text: 'mehr laden',
      ariaLabel: 'mehr laden',
      iconProps: {
        iconName: debouncedNetworkStatus < 7 ? 'HourGlass' : 'AppIconDefaultAdd',
      },
      iconOnly: false,
      onClick: () => {
        setLimit(limit * 2)
        setUrlLimit(limit * 2)
      },
    }
    item.disabled = debouncedNetworkStatus < 7
    items.push(item)
  }

  assertIsString(props.schemaName, 'props.schemaName', 'schemaName muss gesetzt sein')
  return (
    <>
      {bulkEditPanel}
      <KTTheEntityTable
        createPanel={createPanel}
        createButton={createButton}
        loading={loading && !rowData}
        error={error}
        networkStatus={networkStatus}
        commandBarItems={items}
        rowData={rowData}
        internalDesignatorLink={props.internalDesignatorLink || false}
        {...props}
      ></KTTheEntityTable>
    </>
  )
}
