// Eins der Standard-Datenbankobjekte (Kunde etc) in einem Panel neu anlegen oder bearbeiten
// basiert auf https://github.com/rjsf-team/react-jsonschema-form
//
// Üblicherweise benutzt man das nicht direkt
// sondern useAddPanel() oder useEditPanel().
//
// Created by Dr. Maximillian Dornseif 2020-10-15
// Copyright 2020, 2021 Dr. Maximillian Dornseif

import { useMutation } from '@apollo/client'
import { MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { assertIsObject } from 'assertate'
import { DocumentNode } from 'graphql'
import { OMIT_PROPERTIES, cleanGqlInput } from 'graphql-clean-diff'
import { JSONSchema7 } from 'json-schema'
import merge from 'lodash.merge'
import omitDeep from 'omit-deep-lodash'
import React, { useCallback, useState } from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import ReactJson from 'react-json-view'

import { IEntity, TListQueryOptions } from '../../types'
import { useSchema } from '../hooks/useSchema'
import { EntityMutateForm } from './EntityMutateForm'
import { LadeChecker, getErrorMessageBar } from './UiChecker'

// Entity in einem Panel Anlegen/Bearbeiten
interface IEntityMutateProps {
  entity: Partial<IEntity> // Initiale Daten
  isOpen: boolean
  schemaName: string
  mutation: DocumentNode
  title?: string // Überschrift
  onSaved?: (message: React.ReactElement, newData: IEntity) => void // wird mit einer Erfolgs- oder Fehlermeldung aufgerufen
  listQuery?: DocumentNode
  listQueryOptions?: TListQueryOptions
}

/** Neue Entity anlegen.
 *
 * Dafür füllen wir das ganze vorher mit einem Template aus.
 * */
export const EntityCreate = (
  props: IEntityMutateProps & {
    forDesignator: string
  }
) => {
  const newProps = {
    ...props,
    entity: _fillTemplate(props.entity, props.forDesignator),
    forDesignator: undefined,
  } as IEntityMutateProps
  return <EntityMutate title="Anlegen" {...newProps} />
}

/* Mögliche Referenzen Rekusiv ausfüllen.
 * es ist nicht ganz klar, ob das eigentlich noch nötig ist.
 */
function _fillTemplate(entity, forDesignator) {
  const newEntity = { ...entity }
  for (const prop of ['forDesignator', 'referenz', 'kundennr']) {
    if (forDesignator && newEntity[prop] === 'XX00000') {
      newEntity[prop] = forDesignator
    }
  }
  for (const prop in newEntity) {
    if (typeof newEntity[prop] === 'object' && newEntity[prop] != null) {
      newEntity[prop] = _fillTemplate(newEntity[prop], forDesignator)
    }
  }
  return newEntity
}

/** Vorhandene Entity ändern
 * */

export const EntityMutate = (props: IEntityMutateProps) => {
  // Ruft das Schema ab. Überlicherweise ist das schon im Cache
  const { loading, error, schema } = useSchema(props.schemaName)

  if (loading || error || !schema) {
    return <LadeChecker loading={loading} error={error} label={`Lade Schema ${props.schemaName} …`} />
  }
  assertIsObject(props.entity, 'props.entity')
  assertIsObject(schema, 'schema')
  return <_EntityMutateMitSchema {...props} schema={schema} />
}

export const _EntityMutateMitSchema = (
  props: IEntityMutateProps & {
    schema: JSONSchema7
  }
) => {
  const [validationMessageBar, setValidationMessageBar] = useState(null)
  const [successMessage, setSuccessMessage] = useState<React.ReactElement>()
  const entity = { ...props.entity, _keyStr: undefined }

  // GraphQL Mutation definieren
  const refetchQueries = props.listQuery // Wenn wir was geändert haben, Listen Updaten
    ? [{ ...(props.listQueryOptions || {}), query: props.listQuery }]
    : []
  const [mutateEntity, { loading: saving }] = useMutation(props.mutation, {
    onError: (e) => {
      setValidationMessageBar(getErrorMessageBar(e))
    },
    // update list page
    refetchQueries: refetchQueries,
  })

  // Wird aufgerufen, wenn das Panel geschlossen wird
  const saveCallback = useCallback((newDiffVal) => {
    uebertragen(newDiffVal)
  }, [])

  // Daten per GraphQL Mutation an den Server senden.
  const uebertragen = async (formData) => {
    setValidationMessageBar(null)
    const fInput = cleanInputFromSchema(merge({}, entity, formData), props.schema)
    const variables = {
      nr: entity.designator,
      input: fInput,
      designator: entity.designator,
    }
    const result = await mutateEntity({ variables })

    const errorMessageBar = checkErrorMessageBar(result)
    if (errorMessageBar) {
      setValidationMessageBar(errorMessageBar)
    } else {
      setSuccessMessage(getSuccessMessage(entity, result?.data?.entity))
    }
  }

  return (
    <div>
      {validationMessageBar}
      {!successMessage ? (
        <div>
          {saving ? <Spinner size={SpinnerSize.large}>Speichere…</Spinner> : null}
          <div style={{ display: saving ? 'hidden' : 'block' }}>
            <EntityMutateForm schema={props.schema} entity={entity} onSave={(params) => saveCallback(params)}>
              {validationMessageBar}
            </EntityMutateForm>
          </div>
        </div>
      ) : (
        successMessage
      )}
    </div>
  )
}

function checkErrorMessageBar(result) {
  let errorMessageBar = null
  if (result?.errors?.map) {
    errorMessageBar = getErrorMessageBar(result)
  } else if (result?.errors) {
    errorMessageBar = getErrorMessageBar(result.errors)
  } else if (result === undefined) {
    errorMessageBar = getErrorMessageBar(
      'Schwieriger Server Fehler - vermutlich ist das Ding nicht erreichbar.'
    )
  }
  return errorMessageBar
}

/** Die Formulardaten anhand der Schemadaten "aufräumen".
 */
function cleanInputFromSchema(formData: Record<string, any>, schema: JSONSchema7) {
  const newFormData = { ...formData }

  // Properties, die nicht im Schema sind, schreiben wir nicht zurück
  const schemaProps = Object.keys(schema.properties)
  for (const key of Object.keys(newFormData)) {
    // hier fehlt die Rekursion ...
    if (!schemaProps.includes(key)) {
      delete newFormData[key]
    }
  }
  return omitDeep(omitDeep(newFormData, OMIT_PROPERTIES), 'designator')
}

/** Message Bar mit GraphQL-Success
 * */
export function getSuccessMessage(oldObj: Partial<IEntity>, newObj: IEntity): React.ReactElement {
  return (
    <Stack tokens={{ childrenGap: '1em' }}>
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true} truncated>
        Der Datensatz wurde erfolgreich gespeichert:{' '}
      </MessageBar>

      <ReactDiffViewer
        leftTitle="alt"
        oldValue={JSON.stringify(cleanGqlInput(oldObj), null, 2)}
        rightTitle="neu"
        newValue={JSON.stringify(cleanGqlInput(newObj), null, 2)}
        splitView
        hideLineNumbers
        extraLinesSurroundingDiff={1}
        useDarkTheme={false}
        disableWordDiff
      />

      <ReactJson
        name="Details"
        collapsed={true}
        displayObjectSize={false}
        src={{
          old: oldObj,
          new: newObj,
        }}
      />
    </Stack>
  )
}
