import { ColumnApi, GridApi, GridOptions } from '@ag-grid-community/core'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import { AgGridReactProps, AgReactUiProps } from '@ag-grid-community/react/lib/shared/interfaces'
import { CommandBar, ICommandBarItemProps, Panel, PanelType, Stack } from '@fluentui/react'
import { num } from '@hudora/hd-numbro'
import { assertIsDefined } from 'assertate'
import { usePanel } from 'fluentui-hooks'
import startCase from 'lodash.startcase'
import * as R from 'ramda'
import { CSSProperties, useCallback, useState } from 'react'
import ReactJson from 'react-json-view'
import { agGridDefaultOptions, useAgGrid } from 'react-use-aggrid-enterprise'
import { ArrayParam, JsonParam, useQueryParam } from 'use-query-params'

import { dateTimeify } from '../util/myluxon'

interface IAgGridFabricProps {
  columnDefs: Array<AgGridColumnProps>
  rowData: Record<string, unknown>[]
  commandBarItems?: Array<ICommandBarItemProps>
  commandBarFarItems?: Array<ICommandBarItemProps>
  gridOptions?: AgGridReactProps | AgReactUiProps
}

export const AgGridFabric = (props: IAgGridFabricProps) => {
  const { onGridReady, columnApi, api } = useAgGrid()
  const [urlSortModel, setUrlSortModel] = useQueryParam('s', ArrayParam)
  const [urlFilterModel, setUrlFilterModel] = useQueryParam('f', JsonParam)
  const startAutosize = useCallback(() => columnApi?.autoSizeAllColumns(), [columnApi])
  const [isFullWindow, setIsFullWindow] = useState(false)
  const toggleFullWindow = useCallback(() => setIsFullWindow(!isFullWindow), [isFullWindow])
  const onFirstDataRendered = useCallback(
    (params) => {
      setInterval(params.columnApi.autoSizeAllColumns(), 1000) // das scheint nicht zu klappen
      const sortModel = urlSortModel?.map((x) => ({ colId: x.split('~')[0], sort: x.split('~')[1] }))
      if (sortModel) {
        params.columnApi.applyColumnState({
          state: sortModel,
          defaultState: {
            // important to say 'null' as undefined means 'do nothing'
            sort: null,
          },
        })
      }

      const filterModel = urlFilterModel
      if (filterModel) {
        params.api.setFilterModel(filterModel)
      }
    },
    [urlSortModel]
  )

  const margin: CSSProperties = isFullWindow
    ? {
        whiteSpace: 'nowrap',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 11,
        backgroundColor: '#fffffff0',
      }
    : { marginTop: '0px' } // maxWidth: '100vw'

  function prepareCellForClipboard(params) {
    // Export values as formatted
    const colDef = params.column.getColDef()
    // can we find a more generic way to copy formatted values?
    if (colDef.valueFormatter && (colDef.type === 'eurocent' || colDef.type === 'currencycent')) {
      return colDef.valueFormatter({
        ...params,
        data: params.node?.data,
        colDef,
      })
    }
    return params.value
  }

  function onSortChanged(params) {
    const sortModel = params.api.getSortModel()
    setUrlSortModel(sortModel.map((x) => `${x.colId}~${x.sort}`))
  }
  function onFilterChanged(params) {
    const filterModel = params.api.getFilterModel()
    setUrlFilterModel(filterModel)
  }

  const gridOptions: GridOptions = {
    ...agGridDefaultOptions,
    // Sicherstellen, das Euro-Beträge beim Kopieren vernünftig kopiert werden.
    processCellForClipboard: prepareCellForClipboard,
    // for storing the Options see
    // https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/
    onSortChanged,
    onFilterChanged,
    ...props.gridOptions,
  }

  return (
    <main style={margin} aria-label="komplexe AG-Grid Tabelle">
      <div>
        <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
          <TableBar
            isFullWindow={isFullWindow}
            toggleFullWindow={toggleFullWindow}
            startAutosize={startAutosize}
            commandBarItems={props.commandBarItems}
            commandBarFarItems={props.commandBarFarItems}
            columnDefs={props.columnDefs}
            columnApi={columnApi}
            api={api}
          />
        </Stack>
      </div>
      <div
        aria-label="Daten-Tabelle"
        data-testid="aggrid"
        className="ag-theme-balham"
        style={isFullWindow ? { width: '100%', height: '99%' } : { width: '100%', height: '75vh' }}
      >
        <AgGridReact
          {...gridOptions}
          reactUi={true}
          rowData={props.rowData}
          columnDefs={props.columnDefs}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </main>
  )
}

/** Command Bar über dem Grid
 */
export const TableBar = (props: {
  isFullWindow: boolean
  toggleFullWindow
  startAutosize
  commandBarItems?: Array<ICommandBarItemProps>
  commandBarFarItems?: Array<ICommandBarItemProps>
  columnDefs: Array<AgGridColumnProps>
  api?: GridApi
  columnApi?: ColumnApi
}) => {
  const [openConfigPanel, configPanelProps, isConfigPanelOpen] = usePanel(`Konfiguration Liste`)

  const farItems: Array<ICommandBarItemProps> = (
    [
      {
        key: 'Fitwidth',
        text: 'Spalten optimieren',
        ariaLabel: 'FitWidth',
        iconOnly: true,
        iconProps: {
          iconName: 'FitWidth',
        },
        onClick: props.startAutosize,
      },
      {
        key: 'fullwindow',
        text: 'Vollbild',
        ariaLabel: 'Vollbild',
        iconOnly: true,
        iconProps: {
          iconName: props.isFullWindow ? 'BackToWindow' : 'FullScreen',
        },
        onClick: props.toggleFullWindow,
      },
      {
        key: 'listconfig',
        text: 'Listen konfigurieren',
        ariaLabel: 'Listen konfigurieren',
        iconOnly: true,
        iconProps: {
          iconName: 'DataManagementSettings',
        },
        onClick: openConfigPanel,
      },
    ] as Array<ICommandBarItemProps>
  ).concat(props.commandBarFarItems || ([] as Array<ICommandBarItemProps>))
  const commandBarItems = props.commandBarItems ? props.commandBarItems.filter((x) => x !== null) : []
  return (
    <>
      <CommandBar
        items={commandBarItems}
        farItems={farItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        style={{ width: '100%' }}
      />
      <Panel {...configPanelProps} type={PanelType.large}>
        {isConfigPanelOpen && props.columnApi && props.api ? (
          <DisplayGridState columnApi={props.columnApi} api={props.api} columnDefs={props.columnDefs} />
        ) : null}
      </Panel>
    </>
  )
}

function DisplayGridState(props: {
  api: GridApi
  columnApi: ColumnApi
  columnDefs: Array<AgGridColumnProps>
}) {
  const columnState = props.columnApi.getColumnState()
  const filterModel = props.api.getFilterModel()
  // as of version 24.0.0, getSortModel() is deprecated, sort information is now part of Column State. Please use columnApi.getColumnState() instead.
  const sortModel = props.api.getSortModel()
  const rejectHidden = R.reject(R.prop('hide'))
  const hideExcept = R.pluck('colId', rejectHidden(columnState))

  // let columnGroupState = columnApi.getColumnGroupState();
  return (
    <div>
      <ReactJson
        src={{ hideExcept, sortModel, filterModel, columnState, columnDefs: props.columnDefs }}
        displayDataTypes={false}
      />
    </div>
  )
}

/** build column definitions from sample data
 *
 */
export function buildColumnDefs(rows: Record<string, any>[]): Array<AgGridColumnProps> {
  if (!rows || rows.length < 1) {
    return []
  }

  // Ensure that our samle row does represent multiple rows - especially if there are null / undefined fields.
  const sampleRow = { ...rows[0] }
  for (const row of rows) {
    for (const [k, v] of Object.entries(row)) {
      if ((v || v === 0) && !k.startsWith('_')) {
        sampleRow[k] = v
      }
    }
  }
  // Create config. See https://www.ag-grid.com/javascript-grid-column-definitions/
  const columnDefs: Array<AgGridColumnProps> = []
  for (const fieldName of Object.keys(sampleRow)) {
    if (fieldName.startsWith('_')) {
      continue
    }
    // cellStyle: {textAlign: "right"}
    let col = setColFormat(fieldName, sampleRow[fieldName])
    // see https://www.ag-grid.com/javascript-grid-column-properties/
    col = {
      // colId - The unique ID to give the column. This is optional. If missing, the ID will default to the
      // field. If both field and colId are missing, a unique ID will be generated. This ID is used to
      // identify the column in the API for sorting, filtering etc.
      colId: fieldName,

      field: fieldName,
      ...col,
    }
    columnDefs.push(col)
  }
  return columnDefs
}

const type2classMap: Record<string, string> = {
  boolean: 'booleanType',
  booleanproperty: 'booleanType',
  date: 'dateType',
  datetime: 'dateTimeType',
  float: 'floatType',
  integer: 'integerType',
  string: 'stringType',
  'string[]': 'stringArrayType',
  textproperty: 'stringType',
  number: 'numberType',
  timestamp: 'dateTimeType',
}

// Klasse basierend auf dem Ende des Feldnamen setzen.
const nameEndingMap = {
  _euro: ['euroType', ' €'],
  _currency: ['currencyType', ' ¤'],
}
/** AG-Grid Default Format für eine Spalte BigQuery erzeugen
 */
function setColFormat(fieldName: string, sampleValue: any): AgGridColumnProps {
  const theName = fieldName
  let col: AgGridColumnProps = {}
  let key
  if (Array.isArray(sampleValue)) {
    key = `${typeof sampleValue[0]}[]`
  } else {
    key = `${typeof sampleValue}`
  }
  assertIsDefined(
    type2classMap[key],
    `type2classMap[${JSON.stringify(key)}] (${JSON.stringify(sampleValue)})`
  )
  col.cellClass = type2classMap[key]
  col.type = []
  // col.type = [fieldName.type.toLowerCase()]

  // basierend auf dem header name
  col.headerName = startCase(fieldName)

  for (const [ending, tmp] of Object.entries(nameEndingMap)) {
    if (theName.endsWith(ending)) {
      const typ: string = tmp[0]
      const replace: string = tmp[1]
      const strippedName = theName.substring(0, fieldName.lastIndexOf(ending))

      // headerName - The name to render in the column header. If not specified and field is specified, the
      // field name will be used as the header name.
      col.headerName = `${startCase(strippedName)}${replace}`
      // cellStyle - An object of css values / or function returning an object of css values for a particular
      // cell. See Cell Style.

      // cellClass -  Class to use for the cell. Can be string, array of strings, or function that returns a
      // string or array of strings. See Cell Class.
      col.cellClass = typ

      // type - A comma separated string or array of strings containing ColumnType keys which can be used as a
      // template for a column. This helps to reduce duplication of properties when you have a lot of common
      // column properties. See Column Types.
      col.type.push(typ)
    }
  }

  if (col.cellClass) {
    col = { ...col, ...formatTypes[col.cellClass] }
  } else {
    console.error('unknown column cellClass', sampleValue, fieldName)
  }
  return col
}

// Solte alternativ zu ValueRender funktionieren

function valueFormatterEuro(params) {
  // careful: numeral.js seems to have some serious issues, so use numbro.js
  if (isFinite(params.value) && params.value !== null) {
    return num(params.value, {
      mantissa: 2,
    })
  }
  return null
}
function valueFormatterFloat(params) {
  if (isFinite(params.value) && params.value !== null) {
    return num(params.value, {
      mantissa: 2,
    })
  }
  return null
}
function valueFormatterDate(params) {
  return params.value ? dateTimeify(params.value).toISODate() : null
}
function valueFormatterDateTime(params) {
  return params.value ? dateTimeify(params.value).toISO() : null
}
function valueFormatterBoolean(params) {
  switch (params.value) {
    case false:
    case 'false':
      return '❌ nein ' // "batsu" (ばつ)
    case true:
    case 'true':
      return '✅ ja' // alternativ '✔'
    default:
      return '␀'
  }
}
function valueFormatterString(params) {
  return params.value === null ? '␀' : params.value
}
const formatTypes = {
  currencyType: {
    // Generic Currency
    // Feldname endet mit `_currency`
    // In der Überschrift wird `_currency` durch ` ¤` ersetzt
    valueFormatter: valueFormatterEuro,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: { dataType: 'number', numberFormat: { format: '#,##0' } }
  },
  euroType: {
    // Feldname endet mit `_euro`.
    // In der Überschrift wird `_euro` durch ` €` ersetzt
    valueFormatter: valueFormatterEuro,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: { dataType: 'number', numberFormat: { format: '#,##0' } }
  },
  stringType: {
    // wenn der Name auf `nr`, oder `nrs` endet oder `eine_referenz`
    // `referenzen` oder `gruppe` heisst
    // versuchen wir die Nummer(n) zu verlinken
    // excelStyle: { dataType: 'string' }
    valueFormatter: valueFormatterString,
  },
  dateType: {
    // Feldtype ist `date`
    valueFormatter: valueFormatterDate,
    filter: 'agDateColumnFilter',
    // excelStyle: { dataType: 'dateTime', numberFormat: { format: 'yyyy-mm-dd' } }
  },
  dateTimeType: {
    // Feldtype ist `timestamp` oder `datetime`
    valueFormatter: valueFormatterDateTime,
    // excelStyle: {
    //   dataType: 'dateTime',
    //   numberFormat: { format: 'yyyy-mm-dd HH:MM' }
    // }
  },
  floatType: {
    // Feldtype ist `float`
    valueFormatter: valueFormatterFloat,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  integerType: {
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  booleanType: {
    valueFormatter: valueFormatterBoolean,
    filter: 'agSetColumnFilter',
    filterParams: { values: [true, false, null] },
  },
}
