// Anzeige von Belegen
// Created 2021-03-24 by Dr. Maximillian Dornseif
// Copyright HUDORA 2021, 2022

import { gql, useQuery } from '@apollo/client'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React, { useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { KindPage } from '../lib/components/KindPage'
import { KindPage2 } from '../lib/components/KindPage2'
import { LadeChecker } from '../lib/components/UiChecker'
import { Beleg } from './Beleg'

export const BelegeApp = (props) => {
  return <Beleganzeige />
}

function Beleganzeige() {
  const [belegartSP, setBelegartSP] = useQueryParam('belegart', StringParam)
  const [belegart, setBelegart] = useState<string | null>(belegartSP)

  const handleBelegart = (event: React.MouseEvent<HTMLElement>, art: string | null) => {
    setBelegartSP(art || undefined)
    setBelegart(art || undefined)
  }
  const listquery = BELEG_LIST_QUERY_FILTERED
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="center">
        <ToggleButtonGroup value={belegart} exclusive onChange={handleBelegart} aria-label="text alignment">
          <ToggleButton value="PurchaseOrder" aria-label="left aligned">
            PurchaseOrder
          </ToggleButton>
          <ToggleButton value="Invoice" aria-label="centered">
            Invoice
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {/* <KindPage2
        basePath={''}
        componentPath={'belege'}
        schemaName={'BBeleg'}
        title={'Belege'}
        listQuery={listquery}
        entityQuery={BELEG_QUERY}
      /> */}
      <KindPage
        basePath={''}
        componentPath={'belege'}
        schemaName={'EBEinkaufsBeleg'}
        title={'Belege'}
        listQuery={listquery}
        listQueryOptions={{ variables: { belegart } }}
        entityQuery={BELEG_QUERY}
        suppressDefaultEntityPage={true}
        initialLimit={50}
        hideExcept={['designator', 'belegart', 'belegdatum', 'principal', 'empfaenger', 'kundennr', 'lieferantennr', 'created_at']}
        suggestionQuery={SUGGESTIONS_QUERY}
      >
        <EinBelegApp nr={'?'} />
      </KindPage>
    </Stack>
  )
}

export const EinBelegApp = (props: { nr: string }) => {
  const { loading, error, data } = useQuery(BELEG_QUERY, {
    variables: { nr: props.nr },
    skip: !props.nr,
  })
  return (
    <Stack spacing={2}>
      <LadeChecker loading={loading} error={error} label={`Lade Beleg ${props.nr}`} />
      {data ? <Beleg data={data} /> : <span className="empty" />}
    </Stack>
  )
}

const SUGGESTIONS_QUERY = gql`
  query ($term: String!) {
    fulltextSearch: belegSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`
export const BELEG_FRAGMENT = gql`
  fragment einkaufsBelegFragment on H3EinkaufsBeleg {
    designator
    belegart
    belegdatum
    name
    kundennr
    lieferantennr
    empfaenger
    principal
    created_at
  }
`

export const BELEG_QUERY = gql`
  query BelegAnzeige($nr: ID!) {
    entity: h3EinkaufsBeleg(designator: $nr) {
      ...einkaufsBelegFragment
      pdfUrl
      # deliveryStatus {
      #   id
      #   created_at
      #   updated_at
      #   forDesignator
      #   info {
      #     art
      #     started_at
      #     finished_at
      #     deliveries {
      #       at
      #       type
      #       destination
      #       version
      #     }
      #   }
      # }
      raw
    }
  }
  ${BELEG_FRAGMENT}
`

export const BELEG_LIST_QUERY = gql`
  query Belege($cursor: String) {
    nodes: h3EinkaufsBelegPages(after: $cursor) {
      edges {
        cursor
        node {
          ...einkaufsBelegFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${BELEG_FRAGMENT}
`

export const BELEG_LIST_QUERY_FILTERED = gql`
  query BelegeGefiltert($cursor: String, $belegart: String) {
    nodes: h3EinkaufsBelegPages(after: $cursor, filter: { belegart: { eq: $belegart } }) {
      edges {
        cursor
        node {
          _keyStr
          id
          ...einkaufsBelegFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${BELEG_FRAGMENT}
`
