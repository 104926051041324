// JSONschema Darstellen
// https://github.com/coveooss/json-schema-for-humans creates much nicer output,
// we should check that.
// Copyright Dr. Maximillian Dornseif 2020, 2021

import { ValueRender } from '@hudora/react-jsonschema-valuerender'
import { JSONSchema7 } from 'json-schema'
import PropTypes from 'prop-types'
import React from 'react'
import JSONPretty from 'react-json-pretty'
import ReactJson from 'react-json-view'

import { useSchema } from '../../hooks/useSchema'
import { MyErrorBoundary } from '../MyErrorBoundary'
import { LadeChecker } from '../UiChecker'

export const SchemaDoc = ({ schemaName }: { schemaName: string }) => {
  const { loading, error, schema } = useSchema(schemaName)

  if (loading || error) {
    return <LadeChecker loading={loading} error={error} label={`Lade Schema ${schemaName} …`} />
  }

  return (
    <div>
      <SubschemaDoc anchor="TOP" schema={schema} id="T" level={0} />
    </div>
  )
}
SchemaDoc.propTypes = {
  schemaName: PropTypes.string.isRequired,
}

// Rekursiv ein Schema darstellen
// nett wäre https://developer.microsoft.com/en-us/fluentui#/controls/web/groupedlist
function SubschemaDoc(props: { id: string; anchor?: string; schema: JSONSchema7; level: number }) {
  const childrenDoc = []
  let table = []
  let children = null

  if (!props.schema) {
    return <p>Leeres Schema</p>
  }

  if (props.schema.type === 'object' || props.schema.type === 'array') {
    const schemaprops = (props.schema.items as any)?.properties || props.schema.properties
    if (schemaprops) {
      for (const [key, p] of Object.entries(schemaprops)) {
        const property = { $id: key, ...(p as JSONSchema7) }

        // Die Übersichtstabelle
        table = table.concat(
          <SubschemaDocTableRow
            key={`table-a-${props.anchor}-${key}`}
            anchor={`table-a-${props.anchor}-${key}`}
            id={key}
            schema={property}
            required={props.schema.required?.includes(key)}
            linkTarget={`#a-${props.anchor}-${key}`}
          />
        )

        // die Dokumentationstexte - rekursiv erzeugt
        childrenDoc.push(
          <SubschemaDoc
            key={`a-${props.anchor}-${key}`}
            id={`a-${props.anchor}-${key}`}
            schema={property}
            level={props.level + 1}
          />
        )
      }
    }

    children = (
      <MyErrorBoundary>
        <div>
          <h3>Datenfelder {props.schema.title} - Übersicht</h3>
          <table className="hd-mytable">
            <thead>
              <tr>
                <td>Name</td>
                <td>ID</td>
                <td>Format</td>
                <td>Länge</td>
                <td>Pflichtfeld</td>
              </tr>
            </thead>
            <tbody>{table}</tbody>
          </table>

          {props.schema.additionalProperties ? (
            <p>Das Objekt darf weitere, hier nicht genannte Datenfelder enthalten.</p>
          ) : null}

          <h3>Datenfelder {props.schema.title} - Details</h3>
          {childrenDoc.map((x, index) => (
            <div
              id={`${x.anchor}`}
              key={`${index}`}
              style={
                props.level
                  ? {
                      margin: '20px 0 30px',
                      paddingLeft: '20px',
                      borderLeft: '5px solid #1371b8',
                    }
                  : {}
              }
            >
              {x}
            </div>
          ))}
          <h3>Beispieldaten</h3>
          <JSONPretty data={props.schema.examples} />
        </div>{' '}
      </MyErrorBoundary>
    )
  }

  // Hier der eigentliche, nicht rekusive Teil der Dokumentation
  return (
    <MyErrorBoundary>
      <div id={props.id}>
        <Description schema={props.schema} />
        {children}
        <ReactJson name="Details" collapsed={true} src={props.schema} />
      </div>
    </MyErrorBoundary>
  )
}

/** Text-Beschreibung eines Schema-Elements
 * */
function Description(props: { schema: JSONSchema7 }) {
  const examples = props?.schema?.examples as any[]

  return (
    <div>
      <h4>
        {props.schema.title} {props.schema.$id ? `(${props.schema.$id})` : null}
      </h4>

      <div>
        {props.schema.description}
        {props.schema.$ref ? ` (Referenz ${props.schema.$ref})` : null}
      </div>

      {props.schema.minLength ? <p>Die Mindestlänge ist {props.schema.minLength} Zeichen.</p> : null}

      {props.schema.maxLength ? <p>Die Höchstlänge ist {props.schema.maxLength} Zeichen.</p> : null}

      {props.schema.pattern ? (
        <p>
          Der Wert muß folgendem Muster (
          <a href="https://danielfett.de/2006/03/20/regulaere-ausdruecke-tutorial/">Regulären Ausdruck</a>)
          entsprechen: <code>{props.schema.pattern}</code>.
        </p>
      ) : null}

      {props.schema.enum ? (
        <div>
          Das Feld kann nur mit folgenden Werten belegt werden:{' '}
          <ul>
            {props.schema.enum.map((x, index) => (
              <li key={`${index}`}>{x}</li>
            ))}
          </ul>
        </div>
      ) : null}

      {props.schema.readOnly ? <p>Das Feld kann durch den Nutzer nicht geändert werden.</p> : null}

      {props.schema.default ? (
        <p>
          Vorbelegung: <ValueRender value={props.schema.default} />
        </p>
      ) : null}

      <DescriptionFormat schema={props.schema} />

      {examples ? (
        <div>
          Beispiel(e):
          <ul>
            {examples.map((x, index) => (
              <li key={`${index}`}>
                <code>{JSON.stringify(x)}</code>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

/** Individuelle Formate erklären
 **/
function DescriptionFormat(props: { schema: JSONSchema7 }) {
  switch (props.schema?.format) {
    case 'eurocent':
      return (
        <div>
          Der Wert wird als Eurocent in der Datenbank gespeichert, aber als Euro angezeigt und bearbeitet.
        </div>
      )
    case 'currencycent':
      return (
        <div>
          Der Wert wird als Währungs(¤)cent in der Datenbank gespeichert, aber als ¤ angezeigt und bearbeitet.
        </div>
      )
    case 'eurocentlarge':
      return (
        <div>
          Der Wert wird als Eurocent in der Datenbank gespeichert, als Euro bearbeitet und als Euro gerundet -
          zum Teil gerundet auf Tausender etc. angezeigt.
        </div>
      )
    case 'markdown':
      return (
        <div>
          Der Wert wird mehrzeiliger <a href="https://www.markdownguide.org/basic-syntax/">Markdown-Text</a>{' '}
          in der Datenbank gespeichert. Er wird üblicherweise als gerendertes Markdown dargestellt.
        </div>
      )
    default:
      return null
  }
}

/** Eine einzelne Zeile in der Schema-Übersichtstabelle.
 **/
function SubschemaDocTableRow(props) {
  const ppath = props.ppath || ''
  const pprefix = props.pprefix || ''

  if (!props.schema) {
    return <span>Leeres Schema: {props.ppath}</span>
  }

  let table = [
    <tr key={`row_${ppath}${props.id}`}>
      {props.schema.type === 'object' ? (
        <th>{`${pprefix}${props.schema.title}`}</th>
      ) : (
        <td>{`${pprefix}${props.schema.title}`}</td>
      )}
      <td>
        {props.linkTarget ? (
          <a href={props.linkTarget}>
            {ppath}
            {props.id}
          </a>
        ) : (
          `${ppath}${props.id}`
        )}
      </td>
      <td>{props.schema?.format ? props.schema?.format : props.schema?.type}</td>
      <td>
        {props.schema.minLength !== undefined || props.schema.maxLength !== undefined ? (
          <>
            {props.schema.minLength}-{props.schema.maxLength}
          </>
        ) : null}
      </td>
      <td>{JSON.stringify(props.required)}</td>
    </tr>,
  ]
  if (props.schema?.type === 'object') {
    for (const item of Object.entries(props.schema.properties)) {
      const key = item[0]
      const property: JSONSchema7 = item[1]
      table = table.concat(
        <SubschemaDocTableRow
          ppath={`${ppath}${props.id}.`}
          pprefix={`${pprefix}- `}
          key={`${props.anchor}-${key}`}
          anchor={`${props.anchor}-${key}`}
          id={key}
          schema={property}
          required={property.required?.includes(key)}
        />
      )
    }
  }
  if (props.schema?.type === 'array') {
    if (props.schema?.items?.type === 'object') {
      if (!props.schema?.items?.properties) {
        // TODO: Support AnyOf
        throw new Error(`properties fehlen: ${ppath} ${pprefix} ${JSON.stringify(props.schema)}`)
      }
      for (const item of Object.entries(props.schema?.items.properties)) {
        const key = item[0]
        const property: JSONSchema7 = item[1]
        table = table.concat(
          <SubschemaDocTableRow
            ppath={`${ppath}${props.id}[].`}
            pprefix={`${pprefix}- `}
            key={`${props.anchor}-${key}`}
            anchor={`${props.anchor}-${key}`}
            id={key}
            schema={property}
            required={property.required?.includes(key)}
          />
        )
      }
    } else {
      const property: JSONSchema7 = props.schema.items
      const key = ''
      table = table.concat(
        <SubschemaDocTableRow
          ppath={`${ppath}${props.id}[].`}
          pprefix={`${pprefix}- `}
          key={`${props.anchor}-${key}`}
          anchor={`${props.anchor}-${key}`}
          id={key}
          schema={property}
          required={property?.required?.includes(key)}
        />
      )
    }
  }
  return <>{table}</>
}
SubschemaDocTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  ppath: PropTypes.string,
  pprefix: PropTypes.string,
  required: PropTypes.bool,
  schema: PropTypes.object.isRequired,
}
