import * as Sentry from '@sentry/react'
import React from 'react'

export const MyErrorBoundary = (props: { children: any }) => {
  return (
    <Sentry.ErrorBoundary
      showDialog={process.env.NODE_ENV === 'production'}
      fallback={({ error, componentStack }) => (
        <>
          <div>Ein ungeplanter interner Fehler ist aufgetreten:</div>
          <div>
            <pre>{error.toString()}</pre>
          </div>
          <div>
            <pre>{componentStack}</pre>
          </div>
        </>
      )}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
