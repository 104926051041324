// Rendern eines Entities als Tabelle (oder <dl> etc.)
// anhand von Daten und jsonschema.
// Created by Dr. Maximillian Dornseif 2020-10-21
// Copyright 2020, 2021 HUDORA

import { ValueRender } from '@hudora/react-jsonschema-valuerender'
import { assertIsObject, assertIsString } from 'assertate'
import { LinkRender } from 'components/LinkRender'
import { JSONSchema7Definition } from 'json-schema'
import { LadeChecker } from 'lib/components/UiChecker'
import { useSchema } from 'lib/hooks/useSchema'
import React, { CSSProperties } from 'react'
import { IEntity } from 'types'

/** Erstellt aus einem Entity und dem JSON-Schema eine akzeptable Darstellung
 * */
export const EntityRender = ({ entity, schemaName }: { entity: IEntity; schemaName: string }) => {
  const { loading, error, schema } = useSchema(schemaName)
  assertIsString(schemaName, 'schemaName', 'schemaName fehlt')

  if (!entity) {
    return null
  }

  let tableRows = []
  if (schema && schema.type === 'object') {
    // Rekursiv das Objekt und das Schema auseinander pflücken
    tableRows = objHandler(schema, entity, 'p_', tableRows)
  }

  return (
    <div>
      <LadeChecker loading={loading || !schemaName} error={error} label={'Lade Schema …'} />
      <h2>
        {schema?.title} {entity?.designator} {loading} {error}
      </h2>
      <div>
        <table className="hd-mytable" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <td>Name</td>
              <td>Wert</td>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    </div>
  )
}

/** Objekt und Schema rekursiv in HTML Table Rows wandeln.
 *
 * zB http://f.foxel.org/Screen-Shot-2021-01-24-08-03-53.97-1611471839.png
 * */
function objHandler(
  subSchema: JSONSchema7Definition,
  subEntity: Record<string, any>,
  prefix: string,
  tableRows: any[],
  level?: number
) {
  let localTable = []
  level = level ?? 0

  // implementiert das nesting
  const levelStyle = {
    borderLeft: '1px solid #1371b8',
  }

  if (subSchema === true || subSchema === false || !subSchema) {
    // type guard
    return localTable
  }

  if (subSchema?.properties) {
    // Erst alle Scalar Werte
    for (const [key, property] of Object.entries(subSchema?.properties)) {
      assertIsObject(property)
      if (property.type !== 'object') {
        localTable.push(
          <EntityRenderAutoRow
            key={`${prefix}_${key}`}
            schema={property}
            data={subEntity[key]}
            style={level > 0 ? levelStyle : {}}
          />
        )
      }
    }

    // Dann alle Sub-Enties (Objekte)
    for (const [key, property] of Object.entries(subSchema?.properties)) {
      if (property === true || property === false) {
        // type guard
        continue
      }
      if (property.type === 'object') {
        tableRows.push(
          <tr>
            <td>{property.title}</td>
            <td colSpan={3} />
          </tr>
        )
        if (subEntity[key]) {
          localTable.push(
            <tr
              key={`${prefix}-${key}-${subEntity[key]}-${property.$id}-${property.title}1`}
              style={{ marginTop: '130%', borderBottom: '1px' }}
            >
              <th colSpan={2}>
                <b>{property.title}</b>
              </th>
            </tr>
          )
          const v = subEntity[key]
          localTable = localTable.concat(objHandler(property, v, `${prefix}_${key}`, tableRows, level + 1))
        }
      }
    }
  }
  return localTable
}

/** Einen Skalar Wert rendern */
export const EntityRenderAutoRow = (props: {
  data: any
  schema: JSONSchema7Definition
  style: CSSProperties
}) => {
  // Leere Zeilen werden komplett ignoriert
  if (props.data === undefined) {
    return null
  }

  if (props.schema === true || props.schema === false || !props.schema) {
    // type guard
    return null
  }

  let value
  if (
    (props.schema?.title?.endsWith('№') || (props.schema?.items as any)?.title?.endsWith('№')) &&
    props.data &&
    [props.data].flat().length > 0
  ) {
    // Felder, deren Titel auf № endet, werden automatisch verlinkt
    value = (
      <>
        {[props.data]
          .flat()
          .map<React.ReactNode>((x) => <LinkRender key={x} value={x} />)
          .reduce((prev, curr) => [prev, ', ', curr])}
      </>
    )
  } else {
    value = <ValueRender value={props.data} format={props.schema?.format} />
  }

  return (
    <tr style={props.style}>
      <th title={props.schema.description}>{props.schema.title}</th>
      <td>{value}</td>
    </tr>
  )
}
