// AuditPanel
// Created in 2021-01-02 by Maximillian Dornseif
// Copyright Dr. Maximillian Dornseif 2021

import { IconButton } from '@fluentui/react/lib/Button'
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { usePanel } from 'fluentui-hooks'
import React, { useCallback, useState } from 'react'

import { IEntity } from '../../types'
import { AuditLog } from '../components/AuditLog'

export function useAuditPanel(d?: string, e?: IEntity, defaultname = '') {
  const [openPanel, panelProps] = usePanel(`Änderungsverfolgung ${d || defaultname}`.trim())
  const [designator, setDesignator] = useState(d)
  const [entity, setEntity] = useState<IEntity>(e)

  const myOpenPanel = useCallback(
    (d?: string, e?: IEntity): void => {
      d && setDesignator(d)
      e && setEntity(e)
      openPanel()
    },
    [openPanel]
  )

  const auditPanel = (
    <Panel {...panelProps} type={PanelType.large}>
      <AuditLog forDesignator={designator} entity={entity} />
    </Panel>
  )

  const auditButton = (
    <IconButton
      title="Änderungsverfolgung"
      ariaLabel={`Änderungsverfolgung ${d || defaultname}`.trim()}
      iconProps={{ iconName: 'Memo' }}
      onClick={() => openPanel()}
    />
  )

  const auditItem: ICommandBarItemProps = {
    key: 'audit',
    text: 'Änderungsverfolgung',
    ariaLabel: 'Änderungsverfolgung',
    iconOnly: true,
    iconProps: { iconName: 'Memo' },
    onClick: () => openPanel(),
  }

  return {
    auditPanel,
    auditButton,
    auditItem,
    openAuditPanel: myOpenPanel,
    setAuditDesignator: setDesignator,
    setAuditData: setEntity,
  }
}
