// Das ist im Grunde die Stammdaten- Seite

import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { Stack } from '@fluentui/react/lib/Stack'
import { DocumentNode } from 'graphql'
import { EntityRender } from 'lib/components/EntityRender'
import { MyErrorBoundary } from 'lib/components/MyErrorBoundary'
import { useAuditPanel } from 'lib/hooks/useAuditPanel'
import { useEditPanel } from 'lib/hooks/useEditPanel'
import { useRohdatenPanel } from 'lib/hooks/useRohdatenPanel'
import { useSchemaPanel } from 'lib/hooks/useSchemaPanel'
import React, { Suspense } from 'react'
import { IEntity, TListQueryOptions } from 'types'

const AnmerkungenAppLoader = React.lazy(() => import('./AnmerkungenApp'))

interface IEntityPageInnerProps {
  entity: IEntity
  schemaName: string
  mutation?: DocumentNode
  anmerkungDisplay?: boolean
  commandBarItems?: ICommandBarItemProps[]
  children?: React.ReactNode
}

/** Stellt eine Entity (Kundengruppe, einen Verband, eine Versandanweisung etc.)
 *  mit Edit Funktionalität usw. in einer Command-Bar  dar.
 *
 * Wird vom JSON-Schema gesteuert.
 * Standardmässig gibt es Audit, Rohhdaten, Schema und evtl. Edit inder Command Bar.
 * Anmerkungsfuktionalität direkt neben der Entity.
 * Die eigentliche Darstellung erledigt <EntityRender>  */
export const EntityPageInner = (props: IEntityPageInnerProps) => {
  if (!props.entity) {
    return null
  }

  const childrenWithProps = props.children
    ? React.Children.map(props.children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { entity: props.entity } as any)
        }
        return child
      })
    : []

  return (
    <MyErrorBoundary>
      <Stack tokens={{ childrenGap: '0.1em' }}>
        <Stack.Item aria-label={'Entity Navigation'}>
          <EntityCommandBar
            entity={props.entity}
            schemaName={props.schemaName}
            editMutation={props.mutation}
            items={props.commandBarItems}
          />
        </Stack.Item>
        <Stack.Item grow aria-label={'Entity Daten'}>
          <Stack horizontal tokens={{ childrenGap: '2em' }}>
            <EntityRender entity={props.entity} schemaName={props.schemaName} />
            {props.anmerkungDisplay && props.entity?.designator ? (
              <Suspense fallback={<div>Loading…</div>}>
                <AnmerkungenAppLoader forDesignator={props.entity?.designator} />
              </Suspense>
            ) : null}
          </Stack>
        </Stack.Item>
        <Stack.Item>{childrenWithProps}</Stack.Item>
      </Stack>
    </MyErrorBoundary>
  )
}

interface IEntityComandBarProps {
  entity: IEntity
  schemaName: string
  editMutation?: DocumentNode
  listQuery?: DocumentNode
  listQueryOptions?: TListQueryOptions
  items?: ICommandBarItemProps[]
}

/** Implementiert die Iconleiste und zugehörige Funktionalität
 * */
export const EntityCommandBar = (props: IEntityComandBarProps) => {
  const [editItem, editPanel] = useEditPanel({
    entity: props.entity,
    listQuery: props.listQuery,
    listQueryOptions: props.listQueryOptions,
    editMutation: props.editMutation,
    schemaName: props.schemaName,
  })

  const { auditItem, auditPanel } = useAuditPanel(props.entity?.designator, props.entity)
  const [schemaItem, schemalPanel] = useSchemaPanel(props.schemaName)
  const [rohdatenItem, rohdatenPanel] = useRohdatenPanel(props?.entity, undefined, props.schemaName)
  const items = [...(props.items ?? []), editItem].filter(Boolean)
  return (
    <Stack tokens={{ childrenGap: '0.2em' }}>
      <CommandBar items={items} farItems={[auditItem, rohdatenItem, schemaItem]} />
      {editPanel}
      {auditPanel}
      {rohdatenPanel}
      {schemalPanel}
    </Stack>
  )
}
