import React, { MouseEventHandler, ReactNode } from 'react'
import { Link, useLocation } from 'wouter'

// aus autolink2.js
// neu implemetierung von enrichment.js -> nummern_linken()
// es geht darum unsere eindeutigen IDs automatisiert zu verlinken

const patterns = [
  { pattern: 'PO\\d{6,16}', link: '/einkauf/b/{}/', app: 'huFob3' },
  { pattern: 'SP\\d{5}', link: '/einkauf/l/{}/', app: 'huFob3' },
  { pattern: 'EK\\d{6}', link: '/einkauf/ek/{}/', app: 'huFob3' },
  { pattern: 'FD\\d{6}', link: '/fob/o/{}', app: 'huFob3' },
  { pattern: 'IND\\d{6}', link: ' /belege/{}', app: 'huFob3' },
  { pattern: 'INA\\d{6}', link: ' /belege/{}', app: 'huFob3' },

  // der Regular Expression für Artikelnummern ist beschränkter. Wir wollen ja nicht
  // überall Nummern matchen.
  { pattern: 'P[A-Z]\\d{3}', link: '/wawi/prod/{}/', app: 'huWaWi3' },
  { pattern: '\\d{5}', link: '/wawi/item/{}/', app: 'huWaWi3' },
  { pattern: '\\d{5}x', link: '/eap/skum/{}/', app: 'huWaWi3' },
  { pattern: '\\d{5}\\x\\d{2}', link: '/eap/sku/{}/', app: 'huWaWi3' },

  { pattern: 'SV\\d{5}', link: '/stamm/v/{}/', app: 'huWaWi3' },
  { pattern: 'KG\\d{5}', link: '/wawi/g/{}/', app: 'huWaWi3' },
  { pattern: 'SC\\d{5}', link: '/wawi/k/{}/', app: 'huWaWi3' },
  // { pattern: 'HL\\d{5,10}', link: '/stamm/k/{}/m', app: "huWaWi3" },

  { pattern: 'HA\\d{9,16}', link: '/wawi/ic/a/{}/', app: 'huWaWi3' },
  { pattern: 'HE\\d{13,22}', link: '/wawi/ic/e/{}/', app: 'huWaWi3' },
  { pattern: 'KR\\d{9,16}', link: '/wawi/ic/rk/{}/' },
  { pattern: 'LG\\d{1,3}', link: '/wawi/lager/{}/' },

  { pattern: 'LB\\d{6,20}', link: '/ic/bestandsbuch/{}/', app: 'huWaWi3' },
  { pattern: 'LK\\d{11}', link: '/ic/kommi/{}/', app: 'huWaWi3' },

  { pattern: 'LV\\d{6,16}', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'LSLK\\d{6,16}', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'LS\\d{11}', link: '/belege/{}/', app: 'huWaWi3' },

  { pattern: 'RE\\d{6,7}', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'RE\\d{6}S', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'RK\\d{6}', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'AB\\d{6}', link: '/belege/{}/', app: 'huWaWi3' },
  { pattern: 'PR\\d{6}', link: '/belege/{}/', app: 'huWaWi3' },

  { pattern: '0142\\d{10}[A-Z0-9]', link: 'https://tracking.dpd.de/status/de_DE/parcel/{}' },
]

const app2urlprefix = {
  huWaWi3: 'https://huwawi3.hudora.de',
  huFob3: 'https://hufob3.hudora.hk',
}

const regexpen = patterns.map((x) => {
  let link = x.link
  if (x.app && process.env.REACT_APP_NAME !== x.app) {
    link = `${app2urlprefix[x.app]}${link}`
  }

  return { re: new RegExp(`^${x.pattern}$`), link }
})

export function getLink(text) {
  for (const pat of regexpen) {
    if (pat.re.test(text)) {
      return pat.link.replace('{}', encodeURIComponent(text))
    }
  }
  return undefined
}

export const LinkRender = (props: {
  value: string
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLAnchorElement>
}) => {
  const [location] = useLocation()
  let link = getLink(props.value)
  if (link) {
    // some routing based on location
    if (location?.startsWith('/eap')) {
      link = link.replace('/wawi/prod/', '/eap/prod/')
      link = link.replace('/wawi/item/', '/eap/item/')
      link = link.replace('/wawi/skum/', '/eap/skum/')
      link = link.replace('/wawi/sku/', '/eap/sku/')
      link = link.replace('/ic/skum/', '/eap/skum/')
      link = link.replace('/ic/sku/', '/eap/sku/')
    }
    if (location?.startsWith('/stamm')) {
      link = link.replace('/wawi/g/', '/stamm/g/')
      link = link.replace('/wawi/k/', '/stamm/k/')
    }
    if (location?.startsWith('/ic/')) {
      link = link.replace('/einkauf/b', '/ic/po')
    }

    return (
      <Link
        onClick={props.onClick}
        className={link.startsWith('http') ? 'hd-link hd-link-external' : 'hd-link'}
        href={link}
      >
        {props.children || props.value}
      </Link>
    )
  } else {
    return <>{props.children || props.value}</>
  }
}
