import isDate from 'lodash.isdate'
import isObjectLike from 'lodash.isobjectlike'
import { DateTime } from 'luxon'

/** convert anything into a luxon.DateTime
 *  see https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html
 */
export function dateTimeify<T>(val: T): T | DateTime {
  if (typeof val === 'string') {
    if (val.match(/\d{13,14}/)) {
      // legacy datastore
      return DateTime.fromSeconds(parseInt(val) / 1000)
    }
    return DateTime.fromISO(val.replace(' ', 'T'))
  } else if (isObjectLike(val) && 'toDate' in (val as object)) {
    // moment.js objects
    return DateTime.fromJSDate((val as any).toDate())
  } else if (isDate(val)) {
    return DateTime.fromJSDate(val)
  }
  return val
}
