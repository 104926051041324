// Beleg anzeigen
// Copyright 2021 HUDORA

import { Dialog } from '@fluentui/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { CommandBar } from '@fluentui/react/lib/CommandBar'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { Stack } from '@fluentui/react/lib/Stack'
import { ValueRender } from '@hudora/react-jsonschema-valuerender'
import { useDialog, usePanel, useTitle } from 'fluentui-hooks'
import get from 'lodash.get'
import React, { useState } from 'react'

import { LinkRender } from '../components/LinkRender'
import { PdfDialogInner } from '../components/PdfDialog'
import { useRohdatenPanel } from '../lib/hooks/useRohdatenPanel'
import { useSchemaPanel } from '../lib/hooks/useSchemaPanel'
import { dateTimeify } from '../lib/util/myluxon'

export const Beleg = (props) => {
  if (props?.data?.entity) {
    const beleg = JSON.parse(props.data.entity.raw)

    return (
      <Stack>
        <BelegComandBar pdfUrl={props.data.entity.pdfUrl} data={props.data.entity.raw} beleg={beleg} />
        <div>
          <BelegKopf beleg={beleg}></BelegKopf>
          <BelegPositionen beleg={beleg} />
        </div>
        <BelegDelivery deliveryStatus={props.data.entity.deliveryStatus} />
      </Stack>
    )
  }
  return <div>{JSON.stringify(props.data)}</div>
}

interface IBelegComandBarProps {
  beleg: any
  data: unknown // Rohdaten zum Debugging
  pdfUrl: string
}

/** Implementiert die Iconleiste und zugehörige Funktionalität
 * */

export const BelegComandBar = (props: IBelegComandBarProps) => {
  const [openPdfPanel, pdfPanelProps, isPdfPanelOpen] = usePanel(`PDF ${props.beleg.designator}`)
  const [openPdfDialog, pdfDialogProps, , dismissDialog] = useDialog(
    'Beleg Rendern',
    'Bitte wählen Sie ein passendes Template und Ausgabeformat.'
  )

  const [schemaItem, schemalPanel] = useSchemaPanel(`EB${props.beleg.belegart}`)
  const [rohdatenItem, rohdatenPanel] = useRohdatenPanel(props.beleg)

  const nearBarItems: any = [
    {
      key: 'pdf',
      text: 'PDF anzeigen',
      ariaLabel: 'PDF anzeigen',
      iconProps: { iconName: 'PDF' },
      onClick: openPdfPanel,
    },
    {
      key: 'pdfrender',
      text: 'Beleg neu rendern',
      ariaLabel: 'Beleg neu rendern',
      iconProps: { iconName: 'PenWorkspace' },
      onClick: openPdfDialog,
    },
  ]

  const farBarItems = [
    {
      key: 'audit',
      text: 'Änderungsverfolgung',
      ariaLabel: 'Änderungsverfolgung',
      iconOnly: true,
      iconProps: { iconName: 'Memo' },
      disabled: true,
      // onClick: () => openAuditPanel(),
    },
    rohdatenItem,
    schemaItem,
  ]

  return (
    <Stack tokens={{ childrenGap: '0.2em' }}>
      <Stack.Item>
        <CommandBar items={nearBarItems} farItems={farBarItems} />
        <Dialog {...pdfDialogProps}>
          <PdfDialogInner data={props.beleg} onDismiss={dismissDialog}></PdfDialogInner>
        </Dialog>
        <Panel {...pdfPanelProps} type={PanelType.large}>
          {isPdfPanelOpen ? (
            <div style={{ width: '100%', height: '100%' }}>
              <object data={props.pdfUrl} type="application/pdf" width="100%" height="100%">
                <p>
                  Scheinbar klappt das Einbetten nicht. Egal - <a href={props.pdfUrl}>zum PDF!</a>
                </p>
              </object>
            </div>
          ) : null}
        </Panel>

        {/* {auditPanel} */}
        {rohdatenPanel}
        {schemalPanel}
      </Stack.Item>
    </Stack>
  )
}

export function toDateStr(str: string) {
  const dobj = dateTimeify(str)
  return dobj?.toFormat('yyyy-MM-dd')
}
function BelegKopf(props) {
  useTitle(props.beleg.designator)
  return (
    <div>
      <p>
        {props.beleg.bezug?.rechnung?.nr ? (
          <span>
            Zur Rechnung <LinkRender value={props.beleg.bezug.rechnung.nr} />/ vom{' '}
            {toDateStr(props.beleg.bezug.rechnung.datum)}
          </span>
        ) : props.beleg.bezug?.auftrag?.nr ? (
          <span>
            Zu Auftrag <LinkRender value={props.beleg.bezug?.auftrag?.nr} />/
            <code>{props.beleg.bezug?.auftrag?.auftragsnr_kunde}</code> vom{' '}
            {toDateStr(props.beleg.bezug?.auftrag?.datum)}{' '}
          </span>
        ) : null}
        {props.beleg.infotext_kunde ? (
          <>
            <br />
            <span>
              Info: <em>{props.beleg.infotext_kunde}</em>
            </span>
          </>
        ) : null}
      </p>

      <Stack horizontal tokens={{ childrenGap: '2em' }}>
        <Adresse name="Kunde" adr={props.beleg.kunde} />
        <Adresse name="Lieferant" adr={props.beleg.lieferant} />
        <Adresse name="Lieferung an" adr={props.beleg.lieferadresse} />
        <Adresse name="Rechnung an" adr={props.beleg.rechnungsadresse} />
        <Bezug bezug={props.beleg.bezug} />
      </Stack>
      <Referenzen beleg={props.beleg} />
    </div>
  )
  // TODO: Rechnungsadresse
}

const Adresse = (props) => {
  if (!props.adr) return null
  return (
    <p>
      <b>{props.name}:</b> <LinkRender value={props.adr.designator} />
      <br />
      {props.adr.adresse.name}
      <br />
      {props.adr.adresse.zusatz} {props.adr.adresse.iln}
      {props.adr.adresse.zusatz || props.adr.adresse.iln ? <br /> : null}
      {props.adr.adresse.strasse}
      <br />
      {props.adr.adresse.land} {props.adr.adresse.plz} {props.adr.adresse.ort}
    </p>
  )
}

const Bezug = ({ bezug }) => {
  if (!bezug || !bezug?.length) return null
  return (
    <div>
      <b>Bezüge:</b>
      <br />

      <BezugHelfer subBezug={bezug.auftrag} title={'Auftrag'} />
      <BezugHelfer subBezug={bezug.kommiauftrag} title={'Kommiauftrag'} />
      <BezugHelfer subBezug={bezug.lieferschein} title={'Lieferschein'} />
      <BezugHelfer subBezug={bezug.rechnung} title={'Rechnung'} />
      <BezugHelfer subBezug={bezug.intern} title={'Interne Referenz'} />
      <BezugHelfer subBezug={bezug.kunde} title={'Kunden-Referenz'} />
    </div>
  )
}

const BezugHelfer = ({ subBezug, title }) => {
  return subBezug ? (
    <div>
      {title}: <LinkRender value={subBezug.nr} />
      {subBezug.auftragsnr_kunde ? <> / {subBezug.auftragsnr_kunde}</> : null}
      {subBezug.datum ? ` vom ${toDateStr(subBezug.datum)}` : null}
    </div>
  ) : null
}

const Referenzen = ({ beleg }) => {
  if (!beleg) return null
  if (!beleg?.kunde) return null
  return (
    <div>
      <b>Referenzen:</b>
      {beleg?.kunde?.steuerreferenznr ? <span>Steuer№ Kunde: {beleg?.kunde.steuerreferenznr}</span> : null}
      {beleg?.kunde?.iln ? <span>, ILN Kunde: {beleg?.kunde.iln}</span> : null}
      <ReferenzenVerband beleg={beleg} />
    </div>
  )
}

const ReferenzenVerband = ({ beleg }) => {
  return beleg.verband?.nr && beleg.verband?.nr !== 'SV90001' ? (
    <div>
      Verband: {beleg.verband?.name} <LinkRender value={beleg.verband?.nr} />, Mitgliedsnummer:{' '}
      {beleg?.kunde?.kunde_mitgliedsnr_verband}
      {beleg.verband?.unsere_lieferantennr ? (
        <span>unsere Lieferantennummer beim Verband: {beleg.verband?.unsere_lieferantennr}</span>
      ) : null}
    </div>
  ) : null
}

function SuH({ zl }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const onClick = (ev) => setIsExpanded(!isExpanded)

  if (zl) {
    return (
      <>
        <tr key={'zlhead'}>
          <td colSpan={3}>
            <IconButton
              onClick={onClick}
              iconProps={{
                iconName: isExpanded ? 'ChevronDown' : 'ChevronRight',
              }}
            />
          </td>
          <td title={JSON.stringify(zl.faktura)}>S &amp; H / Zusatzleistungen</td>
          <td />
          <td />
          <td align="right" title="props.faktura.warenwert">
            {isExpanded ? null : <ValueRender value={zl?.faktura?.warenwert} format="eurocent" />}
          </td>
          <td align="right" title="faktura.abschlag_betrag">
            {isExpanded ? null : (
              <ValueRender value={zl?.faktura?.positionsabschlaege_betrag} format="eurocent" />
            )}
          </td>
          <td align="right" title="faktura.summe_netto_betraege">
            {isExpanded ? null : <ValueRender value={zl?.faktura?.summe_netto_betraege} format="eurocent" />}
          </td>
          <td></td>
        </tr>
        {isExpanded
          ? zl.positionen?.map((pos) => <BelegPosition myKey={pos.bezug?.posnr_auftrag} pos={pos} />)
          : null}
      </>
    )
  }
  return null
}

export const BelegPositionen = ({ beleg }) => {
  return (
    <section>
      <h2>Positionen</h2>
      <table className="hd-mytable" style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Menge</th>
            <th>Preis</th>
            <th>Einheit</th>
            <th>Item№</th>
            <th>Name</th>
            <th>Volumen</th>
            <th>Packstücke</th>
            <th>KG</th>
            <th>Preis</th>
          </tr>
        </thead>
        <tbody>
          {beleg?.leistungen?.positionen?.map((pos, index) => (
            <BelegPosition myKey={`pa${index}`} pos={pos} />
          ))}

        <tr>
        <th align="right"><ValueRender value={beleg?.logistik.menge} /></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th align="right"><ValueRender value={beleg?.logistik.volumen} /></th>
            <th align="right"><ValueRender value={beleg?.logistik.packstuecke} /></th>
            <th align="right" ><ValueRender value={beleg?.logistik.gewicht_brutto} /></th>
            <th align="right"><ValueRender value={beleg?.faktura?.warenwert} format="currencycent" /></th>
            <th></th>
          </tr>

        </tbody>
      </table>
    </section>
  )
}

export const BelegPosition = ({ pos, myKey }) => {
  return (
    <React.Fragment>
      <tr key={myKey}>
        <td align="right">{pos.menge}</td>
        <TdCurrencyCent data={pos} propName="preis" />
        <td>{pos?.artikel?.einheit ?? 'mal'}</td>
        <td>
          <LinkRender value={pos.itemnr} />
        </td>
        <td>{pos?.artikel?.name ?? pos?.name}</td>
        <td align="right" ><ValueRender value={pos?.logistik.volumen} /></td>
        <td align="right" ><ValueRender value={pos?.logistik.packstuecke} /></td>
        <td align="right" ><ValueRender value={pos?.logistik.gewicht_brutto} /></td>
        <TdCurrencyCent data={pos} propName="faktura.warenwert" />
      </tr>
      {pos?.infotext_kunde ? (
        <tr>
          <td colSpan={10}>{pos?.infotext_kunde}</td>
        </tr>
      ) : null}
    </React.Fragment>
  )
}

function TdCurrencyCent({ data, propName }) {
  return (
    <td align="right" title={propName}>
      <ValueRender value={get(data, propName)} format="currencycent" />
    </td>
  )
}


const BelegDelivery = ({ deliveryStatus }) => {
  if (!deliveryStatus) {
    return null
  }

  return (
    <div>
      <h2>Auslieferungsstatus</h2>
      Auslieferung Stand {deliveryStatus.updated_at}. Beginn war {deliveryStatus.created_at}.
      <table>
        <tbody>
          {deliveryStatus.info?.map((info) => (
            <BelegDeliveryInfo key={info.art} info={info} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

// "art": "SammelRechnungskorrektur",
// "started_at": "2021-07-09T21:00:08.693Z",
// "finished_at": "2021-07-09T21:00:09.548Z",

const BelegDeliveryInfo = ({ info }) => {
  if (!info?.deliveries) {
    return null
  }

  return (
    <>
      <tr>
        <th colSpan={3}>{info.art}</th>
        <td>
          <i>{info.finished_at ? `${info.finished_at} erledigt` : null}</i>
        </td>
      </tr>
      {info?.deliveries?.map((d) => (
        <BelegDeliveryInfoDelivery key={d.at} delivery={d} />
      ))}
    </>
  )
}

const BelegDeliveryInfoDelivery = ({ delivery }) => {
  return (
    <tr>
      <td>{delivery.type}</td>
      <td>{delivery.at}</td>
      <td>{delivery.version}</td>
      <td>{delivery.destination}</td>
    </tr>
  )
}
