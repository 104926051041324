// Formular für EntityMutate
// basiert auf https://github.com/rjsf-team/react-jsonschema-form
//
// Created by Dr. Maximillian Dornseif 2021-09-27
// Copyright 2021 Dr. Maximillian Dornseif

import { MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Separator } from '@fluentui/react/lib/Separator'
import Form from '@hudora/hd-rjsf'
import { assertIsObject, assertIsString } from 'assertate'
import cleanDeep from 'clean-deep'
import { cleanDiff, cleanGqlInput } from 'graphql-clean-diff'
import { JSONSchema7 } from 'json-schema'
import { jsonDefault } from 'json-schema-default'
import { jsonEmptyArrays } from 'json-schema-empty-arrays'
import { jsonEmptyStrings } from 'json-schema-empty-strings'
import isEmpty from 'lodash.isempty'
import isPlainObject from 'lodash.isplainobject'
import merge from 'lodash.merge'
import transform from 'lodash.transform'
import React, { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import ReactJson from 'react-json-view'

import { IEntity } from '../../types'
import { dataFitsSchema } from '../util/jsonschema'

export const EntityMutateForm = (props: {
  schema: JSONSchema7
  entity?: Partial<IEntity>
  onSave: (entity) => void
  children?: React.ReactElement<any>
}) => {
  assertIsString(props.schema.title, 'schema.title')
  assertIsObject(props.entity, 'entity')
  // Initiale Formulardaten - wenn wir ein neues Entity übergeben bekommen,
  // müssten wir das neu initialisieren

  // Defaults aus dem JSON Schema.
  const { entity, cleanEntity } = _prepareEntityForForm(props.schema, props.entity)

  const [currentFormData, setCurrentFormData] = useState<any>(entity)
  useEffect(() => {
    // We are called for a new entity, replace defaults
    // if (props.entity.designator !== currentFormData.designator) {
    setCurrentFormData(entity)
    // }
    // Das finde ich alles etwas fishy, muss ich zugeben
  }, [props.entity])

  // Aktuelle Änderungen: was wurde geändert und ist das "valid"?
  const valid = dataFitsSchema(currentFormData, props.schema)
  // das diff müssen wir gegen die Original-Input-Daten anwenden;
  // in `entity` sind ja schon die Defaults verarbeitet.
  const [diffVal, changedInfo] = _getDiff(props.entity, currentFormData, props.schema)

  return (
    <section aria-label="Datensatz bearbeiten">
      <Stack tokens={{ childrenGap: '0.5em' }}>
        <Form
          schema={props.schema}
          formData={currentFormData}
          liveValidate
          onChange={({ formData }) => {
            setCurrentFormData(formData)
          }}
          // onSubmit={({ formData }) => uebertragen(formData)}
        >
          {!valid ? (
            <MessageBar messageBarType={MessageBarType.warning}>
              Die Daten entsprechen nicht dem erwarteten Schema (siehe oben).
            </MessageBar>
          ) : null}
          <div data-testid="editpanelbutton" style={{ paddingTop: '0.5em' }}>
            {changedInfo === null ? (
              <PrimaryButton disabled text="Nichts geändert" type="submit" />
            ) : (
              <PrimaryButton
                disabled={!valid}
                text="Speichern"
                type="submit"
                onClick={(ev) => {
                  props.onSave(diffVal)
                }}
              />
            )}
          </div>
        </Form>
        {props.children}
        <p>Daten entsprechen dem Schema {props.schema.title}.</p>
        <section aria-label="diffVal" aria-hidden={true} style={{ display: 'none' }}>
          <span>{JSON.stringify(diffVal)}</span>
        </section>
        <section aria-label="currentFormData" aria-hidden={true} style={{ display: 'none' }}>
          <span>{JSON.stringify(currentFormData)}</span>
        </section>
        {changedInfo}
        <ReactJson
          name="Details"
          collapsed={true}
          displayObjectSize={false}
          src={{
            property: props.entity,
            cleanEntity,
            old: entity,
            new: cleanInputFromSchema(currentFormData, props.schema),
            schema: props.schema,
          }}
        />
      </Stack>
    </section>
  )
}

export function _prepareEntityForForm(schema: JSONSchema7, inEntity?: Partial<IEntity>) {
  // null etc aus den übergebenen Daten entfernen
  // das schwierige ist, dass bei der Schema-Validierung leere Strings auf
  // die Feld Pattern geprüft werden. Ein "" ist zB keien gültige E-Mail Adresse
  // Leere strings müssen bei der Validierung `undefined` sein.
  // Also nehmen wir die raus.
  // Das Problem ist, dass dann die Defaults angewendet werden.
  // Wir können bei diesem Vorgehen kein leeres Feld haben,
  // wenn für dieses Feld ein Default existiert.
  // Siehe https://github.com/rjsf-team/react-jsonschema-form/issues/402
  // https://community.retool.com/t/json-schema-form-ui-emptyvalue-issues/4837
  // https://github.com/rjsf-team/react-jsonschema-form/issues/605
  const cleanEntity = cleanDeep(inEntity, {
    emptyStrings: true,
    nullValues: true,
    emptyArrays: false,
    emptyObjects: true,
  })
  const entity = cleanGqlInput(merge({}, jsonDefault(schema), jsonEmptyArrays(schema), cleanEntity))
  return { entity, cleanEntity }
}

/** Die Formulardaten anhand der Schemadaten "aufräumen".
 * Besser wäre es, die GraphQL Definition vom Server
 * zu nehmen, aber da hab ich momentan keinen Ansatz zu.
 */
function cleanInputFromSchema(formData: Record<string, any>, schema: JSONSchema7) {
  const newFormData = { ...formData }

  // Properties, die nicht im Schema sind, schreiben wir nicht zurück
  const schemaProps = Object.keys(schema.properties)
  for (const key of Object.keys(newFormData)) {
    // hier fehlt die Rekursion ...
    if (!schemaProps.includes(key)) {
      delete newFormData[key]
    }
  }
  return cleanGqlInput(newFormData)
}

/** Gibt die geänderten Daten als Object und ein Reacht-Element mit Erklärung zurück
 * */
function _getDiff(entity: object, currentFormData: object, schema: JSONSchema7) {
  let finalFormData = trimmAll(currentFormData)
  /// rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
  finalFormData = merge({}, jsonEmptyStrings(schema), fixStrings(entity, finalFormData))
  finalFormData.definitions = undefined
  const diffVal = cleanDiff(entity, finalFormData)

  const changed = !isEmpty(diffVal) ? (
    <div>
      <Separator>geänderte Werte</Separator>
      <section aria-label="geänderte Werte">
        <JSONPretty data={diffVal} />
      </section>
    </div>
  ) : null
  return [diffVal, changed]
}

/** trims all Strings within an Object
 * */
export function trimmAll(obj) {
  return transform(obj, (result, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = trimmAll(value)
    }
    // trim strings
    if (typeof value === 'string') {
      value = value.trim()
    }
    result[key] = value
  })
}

/** rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
 * */
export function fixStrings(original, newObject) {
  return transform(
    { ...original },
    (result, value, key) => {
      // Recurse into arrays and objects.
      if (newObject?.[key] && (Array.isArray(value) || isPlainObject(value))) {
        value = fixStrings(value, newObject[key])
      }
      // fix strings
      if (typeof value === 'string' && !newObject[key]) {
        newObject[key] = ''
      }
      result[key] = newObject[key]
    },
    newObject
  )
}
