// Generische Table Funktionalität
// Basiert auf (AG-Grid)
// Erzeugt Spaltendefinitionen aus Schema
//
// Created by Maximillian Dornseif August 2021
// Copyright 2021, 2022 Maximillian Dornseif

import { GridOptions } from '@ag-grid-community/core'
import { AgGridColumnProps } from '@ag-grid-community/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { Stack } from '@fluentui/react/lib/Stack'
import { num } from '@hudora/hd-numbro'
import { valueRender } from '@hudora/react-jsonschema-valuerender'
import { assertIsArray, assertIsDefined, assertIsObject, assertIsString } from 'assertate'
import { DocumentNode } from 'graphql'
import { JSONSchema7 } from 'json-schema'
import get from 'lodash.get'
import * as R from 'ramda'
import React, { useCallback, useState } from 'react'
import { agGridDefaultOptions } from 'react-use-aggrid-enterprise'
import { Link, useLocation } from 'wouter'

import { LinkRender } from '../../components/LinkRender'
import { IEntity, TListQueryOptions } from '../../types'
import { useAuditPanel } from '../hooks/useAuditPanel'
import { useEditPanel } from '../hooks/useEditPanel'
import { useSchema, useSchemata } from '../hooks/useSchema'
import { useSchemaPanel } from '../hooks/useSchemaPanel'
import { getPathsDeep } from '../util/get-value-deep'
import { AgGridFabric } from './AgGridFabric'
import { LadeChecker } from './UiChecker'

export interface IRowTool {
  title: string
  iconName: string
  onClick: (fieldData: IEntity, event: any) => void
}

export interface IEntityTableProps {
  title: string // Überschrift
  schemaName: string // Name des Daten-Schemas, dass angezeigt wird
  rowData: Record<string, unknown>[]
  hideExcept?: string[] // alle Spalten ausser denen mit diesen Namen ausblenden
  commandBarItems?: Array<ICommandBarItemProps>
  rowToolsEnabled?: boolean
  internalDesignatorLink?: boolean

  editMutation?: DocumentNode
  listQuery?: DocumentNode
  listQueryOptions?: TListQueryOptions
}

export const EntityTable = (props: IEntityTableProps) => {
  const { auditPanel, openAuditPanel } = useAuditPanel()
  const { loading, error, schema } = useSchema(props.schemaName)
  const [schemaNames, setSchemaNames] = useState([])
  const { schemata } = useSchemata(schemaNames)
  const [, editPanel, , openEditPanel] = useEditPanel({
    schemaName: props.schemaName,
    editMutation: props.editMutation,
    listQuery: props.listQuery,
    listQueryOptions: props.listQueryOptions,
    entity: undefined,
  })
  const openRowEdit = useCallback((fieldData) => {
    openEditPanel(fieldData)
  }, [])

  if (loading || error || !schema) {
    return (
      <>
        {props.title !== '' ? <h2>{props.title}</h2> : null}
        <LadeChecker loading={loading || !schema} error={error} label={`Lade Schema ${props.schemaName} …`} />
      </>
    )
  }
  if (!props?.rowData || props?.rowData.length < 1) {
    return (
      <>
        {props.title !== '' ? <h2>{props.title}</h2> : null}
        <Stack verticalAlign="center">
          <Stack.Item align="center">
            <>(Noch) keine Daten empfangen</>
          </Stack.Item>
        </Stack>
      </>
    )
  }

  // Die Spalten Definition aus dem Schema erzeugen
  assertIsObject(schema)
  assertIsArray(props?.rowData)

  // Alle verwendeten Schemata finden
  const schemaNameMap = Object.fromEntries(
    Object.entries(getPathsDeep<string>('__typename', props?.rowData)).map(([k, v]) => {
      return [k.replace('.__typename', '').replace(/\[\d+\]\.?/, ''), v]
    })
  ) as Record<string, string>
  // Schema namen extraieren
  const nsn = R.uniq(Object.values(schemaNameMap))
  if (!R.equals(nsn, schemaNames)) {
    setSchemaNames(nsn)
  }
  const schemaMap = Object.fromEntries(
    Object.entries(schemaNameMap).map(([k, v]) => {
      return [k, schemata[v]]
    })
  )

  const columnDefs = _buildColumnDefs(
    props?.rowData,
    schema,
    schemaMap,
    props.hideExcept,
    props.internalDesignatorLink || false
  )

  // möglicherweise noch Piktogramme voran stellen
  const rowTools: Array<IRowTool> = []
  if (props.rowToolsEnabled) {
    if (props.editMutation) {
      rowTools.push({
        title: 'Edit',
        iconName: 'Edit',
        onClick: openRowEdit,
      })
    }
    rowTools.push({
      title: 'Audit',
      iconName: 'Memo',
      onClick: (fieldData) => openAuditPanel(fieldData?.designator, fieldData),
    })
  }

  if (rowTools.length > 0) {
    columnDefs.unshift({
      headerName: '🛠',
      field: '_tools',
      pinned: 'left',
      cellRendererParams: { buttons: rowTools },
      cellRendererFramework: ToolsRender, // https://www.ag-grid.com/javascript-grid-cell-rendering-components/#react-cell-rendering
      maxWidth: 85,
      sortable: false,
      suppressFiltersToolPanel: true,
      enableRowGroup: false,
    })
  }

  return (
    <>
      {props.title !== '' ? <h2 style={{ marginBottom: '0px', zIndex: 12 }}>{props.title}</h2> : null}
      <EntityTableInner {...props} columnDefs={columnDefs} />
      {editPanel}
      {auditPanel}
    </>
  )
}

interface ISchemaTableInnerProps extends IEntityTableProps {
  columnDefs: Array<AgGridColumnProps>
  title: string // Überschrift
  schemaName: string // Name des Daten-Schemas, dass angezeigt wird
  rowData: Record<string, unknown>[]
  hideExcept?: string[] // alle Spalten ausser denen mit diesen Namen ausblenden
  commandBarItems?: Array<ICommandBarItemProps>
}

export const EntityTableInner = (props: ISchemaTableInnerProps) => {
  const [schemaItem, schemalPanel] = useSchemaPanel(props.schemaName)

  const farItems: Array<ICommandBarItemProps> = [schemaItem]
  const gridOptions: GridOptions = { ...agGridDefaultOptions }
  gridOptions.columnTypes = columnTypes
  return (
    <>
      <AgGridFabric
        gridOptions={gridOptions}
        rowData={props.rowData}
        columnDefs={props.columnDefs}
        commandBarFarItems={farItems}
        commandBarItems={props.commandBarItems}
      />
      {schemalPanel}
    </>
  )
}

/** Die Spalten Definition aus dem Schema erzeugen.
 */
function _buildColumnDefs(
  rowData: Record<string, unknown>[],
  schema: JSONSchema7,
  schemaMap: Record<string, JSONSchema7>,
  hideExcept?: string[],
  internalDesignatorLink?: boolean
): Array<AgGridColumnProps> {
  let sortlisten: string[][] = hideExcept ? [hideExcept] : []

  // Die Rohddaten sind unsere Grundlage
  const columnDefMap = new Map<string, AgGridColumnProps>()
  _extractColDefFromRow(rowData[0], columnDefMap, internalDesignatorLink || false)
  for (const row of rowData) {
    _extractColDefFromRow(row, columnDefMap, internalDesignatorLink || false)
  }
  sortlisten.push(_extractColDefFromSchema(schema, columnDefMap, schemaMap))
  sortlisten = sortlisten.concat(
    Object.entries(schemaMap).map(([k, v]) => {
      return _extractColDefFromSchema(v, columnDefMap, schemaMap, `${k}.`)
    })
  )

  for (const colDef of columnDefMap.values()) {
    if (hideExcept) {
      colDef.hide = !hideExcept.includes(colDef.field)
    }
  }

  // Jetzt müssen wir die Spalten noch in die richtige Reihenfolge
  // bringen. Apollo Client liefert die Spalten nicht (mehr) in
  // der Reihenfolge zurück, wie sie in der Query stehen.
  // Sortlisten ist eine Liste mit Listen von Spaltennamen - die
  // arbeiten wir nacheinander ab
  // Für alle Fälle nehmen wir die Spalten aus der ersten Zeile noch dazu.
  if (rowData[0]) {
    sortlisten.push(Object.keys(rowData[0]))
  }

  const columnDefs: Array<AgGridColumnProps> = []
  for (const sortliste of sortlisten) {
    for (let colname of sortliste) {
      colname = colname.replace('.', '-')
      if (columnDefMap.get(colname) !== undefined) {
        columnDefs.push(columnDefMap.get(colname))
        columnDefMap.delete(colname)
      }
    }
  }
  for (const colname of columnDefMap.keys()) {
    if (columnDefMap.get(colname) !== undefined) {
      columnDefs.push(columnDefMap.get(colname))
      columnDefMap.delete(colname)
    }
  }
  return columnDefs
}

function _extractColDefFromRow(
  row: Record<string, any>,
  columnDefMap: Map<string, AgGridColumnProps>,
  internalDesignatorLink: boolean,
  prefix = ''
): void {
  if (row) {
    for (const key of Object.keys(row)) {
      if (row?.[key]?.__typename) {
        // Nesting happens
        _extractColDefFromRow(row?.[key], columnDefMap, internalDesignatorLink, `${prefix}${key}.`)
      } else if (row?.[key] !== null && !key.startsWith('_')) {
        columnDefMap.set(
          `${prefix.replace('.', '-')}${key}`,
          _getColDefFromRow(prefix, key, internalDesignatorLink)
        )
      }
    }
  } else {
    console.error('leere Zeile')
  }
}
function _getColDefFromRow(prefix: string, key: string, internalDesignatorLink: boolean): AgGridColumnProps {
  const colDef: AgGridColumnProps = { field: `${prefix}${key}` }
  if (key.startsWith('_') || key === 'id') {
    colDef.hide = true
  }
  if (key.endsWith('№')) {
    colDef.cellRendererFramework = LinkRender
  }
  if (key === 'designator' && internalDesignatorLink) {
    colDef.cellRendererFramework = DetailsRender
  }
  if (key.endsWith('_at')) {
    colDef.type = 'date-time'
  }
  return colDef
}

const DetailsRender = (props: { value: string }) => {
  const [location] = useLocation()
  const link = `${location}/${props.value}`
  return link ? <Link href={link}>{props.value}</Link> : <>{props.value}</>
}

/** AG-Grid Spalten Definition anhand des Schemas erzeugen.
 *
 * Das sollte von der Formatierung möglichst ähnlich stattfinden, wie
 * auch das Rendern einzelner entities. Siehe <SchemaEntityDisplay />
 * und <ValueRender />
 * Liefert eine Liste von Feldnamen zurück
 */
function _extractColDefFromSchema(
  schema: JSONSchema7,
  columnDefMap: Map<string, AgGridColumnProps>,
  schemaMap: Record<string, JSONSchema7>,
  prefix = ''
): string[] {
  let feldnamen: string[] = []
  if (!schema) {
    return feldnamen
  }
  for (const key of Object.keys(schema.properties)) {
    // Jetzt die Spalten mit den Daten aus dem Schema aufbereiten
    feldnamen.push(`${prefix}${key}`)
    const schemaProp = get(schema.properties, key)

    if (schemaProp === true || schemaProp === false) {
      // type guard
      continue
    }

    if (!schemaProp) {
      console.log(`${key} gibt es nicht`, schemaProp, get(schema.properties, key), 'in', schema)
      continue
    }

    if (schemaProp.type === 'object') {
      // Rekursion
      feldnamen = feldnamen.concat(
        _extractColDefFromSchema(schemaProp, columnDefMap, schemaMap, `${prefix}${key}.`)
      )
      continue
    }

    const colDef = columnDefMap.get(`${prefix.replace('.', '-')}${key}`)

    if (colDef !== undefined) {
      colDef.headerTooltip = `${key}: ${schemaProp.description}`
      colDef.type = schemaProp.format || schemaProp.type
      colDef.headerName = schemaProp.title
      // Ab hier sollten wir wie in <SchemaEntityDisplay />
      // und <ValueRender /> funktionieren.
      if (schemaProp.format === 'eurocent' || schemaProp.format === 'eurocentlarge') {
        colDef.headerName = `${schemaProp.title} €`
      }
      if (schemaProp.format === 'currencycent') {
        colDef.headerName = `${schemaProp.title} ¤`
      }

      if (schemaProp?.title?.endsWith('№') && colDef.cellRendererFramework === undefined) {
        colDef.cellRendererFramework = LinkRender
      }
    } else {
      // console.log(`${prefix.replace('.', '-')}${key}`)
    }

    // type - A comma separated string or array of strings containing ColumnType keys which can be used as a template for a column. This helps to reduce duplication of properties when you have a lot of common column properties.
    // initialHide
    // initialSort
    // initialSort Same as 'sort', except only applied when creating a new column. Not applied when updating column definitions.
    // sortIndex If doing multi-sort by default, the order which column sorts are applied.
    // initialSortIndex Same as 'sortIndex', except only applied when creating a new column. Not applied when updating column definitions.
    // sortingOrder Array defining the order in which sorting occurs (if sorting is enabled). Options: null, 'asc', 'desc'
    // checkboxSelection boolean boolean or Function. Set to true (or return true from function) to render a selection checkbox in the column. Default: false
    // cellStyle The style to give a particular cell. See Cell Style.
    // cellClass The class to give a particular cell. See Cell Class.
    // valueFormatter(params) Function or expression. Formats the value for display.
  }
  return feldnamen
}

// Ab hier sollten wir wie in <SchemaEntityDisplay />
// und <ValueRender /> funktionieren.
function valueFormatterCent(params) {
  // careful: numeral.js seems to have some serious issues, so use numbro.js
  if (isFinite(params.value) && params.value !== null) {
    return num(params.value / 100, {
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    })
  }
  return null
}
function valueFormatterDate(params) {
  return valueRender(params.value, 'date')
}

function valueFormatterDateTime(params) {
  return valueRender(params.value, 'date-time')
}

function valueFormatterBoolean(params) {
  return valueRender(params.value)
}

const columnTypes = {
  eurocent: {
    // Darstellung wird durch 100 geteilt und auf zwei Nachkommastellen gepadded.
    // In der Überschrift wird `€` angehängt.
    valueFormatter: valueFormatterCent,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: {dataType: 'number', numberFormat: {format: '#,##0.00'}}
  },
  currencycent: {
    // Darstellung wird durch 100 geteilt und auf zwei Nachkommastellen gepadded.
    // In der Überschrift wird `¤` angehängt.
    valueFormatter: valueFormatterCent,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: {dataType: 'number', numberFormat: {format: '#,##0.00'}}
  },
  eurocentlarge: {
    // Darstellung wird durch 100 geteilt Nachkommastellen entfernt.
    // In der Überschrift wird `€` angehängt.
    valueFormatter: valueFormatterCent,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: {dataType: 'number', numberFormat: {format: '#,##0.00'}}
  },
  date: {
    // Feldtype ist `date`
    valueFormatter: valueFormatterDate,
    filter: 'agDateColumnFilter',
    // excelStyle: {
    //   dataType: 'dateTime',
    //   numberFormat: { format: 'yyyy-mm-dd' },
    // },
  },
  'date-time': {
    // Feldtype ist `timestamp` oder `datetime`
    valueFormatter: valueFormatterDateTime,
    filter: 'agDateColumnFilter',
    // excelStyle: {
    //   dataType: 'dateTime',
    //   numberFormat: { format: 'yyyy-mm-dd HH:MM:SS' },
    // },
  },
  integer: {
    // Feldtype ist `float`
    // valueFormatter: valueFormatterFloat,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  number: {
    // Feldtype ist `float`
    // valueFormatter: valueFormatterFloat,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  string: {
    // wenn der Name auf `nr`, oder `nrs` endet oder `eine_referenz`
    // `referenzen` oder `gruppe` heisst
    // versuchen wir die Nummer(n) zu verlinken
    // excelStyle: { dataType: 'string' },
  },
  email: {},
  boolean: {
    valueFormatter: valueFormatterBoolean,
    filter: 'agSetColumnFilter',
    filterParams: { values: [true, false, null] },
  },
  null: { valueFormatter: valueFormatterBoolean },
  object: {},
  array: {},
}

/** Ag-Grid Zelle mit Buttons */
const ToolsRender = (props) => {
  // see https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/#react
  const buttons: Array<IRowTool> = props?.colDef?.cellRendererParams?.buttons
  if (!buttons) {
    return null
  }
  return (
    <>
      {buttons?.map((x) => {
        assertIsString(x.iconName, 'iconName', 'icon fehlt')
        assertIsDefined(x.onClick, 'onClick', 'onClick Handler fehlt')
        return (
          <IconButton
            key={x.title}
            iconProps={{ iconName: x.iconName }}
            title={x.title}
            ariaLabel={x.title}
            onClick={(ev): any => x.onClick(props.data, ev)}
          />
        )
      })}
    </>
  )
}
