import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import React, { PropsWithChildren } from 'react'

export function FehlerChecker(props) {
  let error = props.error
  if (!error) {
    return null
  }
  const info = error?.networkError?.result?.errors.map((x) => x.message).join(' / ') || ''
  if (error?.stack && error?.message) {
    // it's an error, probably
    console.error(error)
    error = error.message
  }
  console.log(error)

  return (
    <Dialog
      hidden={!props.error}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Leider ist ein Fehler beim Server-Zugriff aufgetreten.',
        subText: 'Bitte laden Sie die Seite erneut.',
      }}
      modalProps={{ isBlocking: true }}
    >
      {error}
      <br />
      {info}
      <DialogFooter>
        <PrimaryButton
          text="Mist!"
          onClick={() => {
            window.location.pathname = '/'
          }}
        />
      </DialogFooter>
    </Dialog>
  )
}
FehlerChecker.propTypes = {
  error: PropTypes.object,
}

interface ILadeCheckerProps {
  label?: string
  loading: boolean
  error?: Error
  data?: any
}

// wenn `loading` gesetzt ist, einen Lade-Indikator anzeigen
// see https://developer.microsoft.com/en-us/fluentui#/controls/web/spinner
export const LadeChecker = (props: PropsWithChildren<ILadeCheckerProps>) => {
  const conf = {
    label: 'lade …',
    labelPosition: 'bottom',
    size: SpinnerSize.medium,
    data: true,
    ...props,
  }
  if (props.loading) {
    return (
      <Spinner
        label={conf.label}
        ariaLive="assertive"
        role="status"
        ariaLabel={`Laden ${props.label || ''} …`}
        aria-busy="true"
      />
    )
  }
  if (props.error) {
    if (`${props?.error}`.search(': No more mocked responses for the query') > -1) {
      // this should happen only during testing
      throw props.error
    }

    console.error(props?.error)
    return (
      <section role="alert">
        {conf.label}: Leider ist ein Fehler aufgetreten: {graphQlFehlerAufbereiten(props.error)}
      </section>
    )
  }
  return conf.data ? <section className="ladeCheckerdone">{props.children}</section> : null
}

/** Fehler nach bestem Wissen udn gewissen in End-User Text umwandeln
 */
export function graphQlFehlerAufbereiten(error: any): string {
  if (error?.networkError?.result?.errors?.map) {
    return error?.networkError?.result?.errors.map((x) => x.message).join(' / ')
  } else if (error?.errors?.map) {
    return error?.errors.map((x) => x.message).join(' / ')
  } else {
    return error.message
  }
}

/** Message Bar mit GraphQL-Fehler erzeugen
 * */
export function getErrorMessageBar(error) {
  //  error.networkError.result.errors.map(e => `${e.extensions.code}: ${e.message}`)
  const text = graphQlFehlerAufbereiten(error)
  Sentry.captureMessage(text)
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
      truncated={true}
    >
      <div>
        <div>
          <b>Es ist ein Fehler beim Server-Zugriff auftgetreten:</b>
        </div>
        <div>{text}</div>
      </div>
    </MessageBar>
  )
}

// [UserInputError: Variable "$designator" of required type "String!" was not provided.] {
//   locations: [ { line: 1, column: 11 } ],
//   path: undefined,
//   extensions: { code: 'BAD_USER_INPUT', exception: { stacktrace: [Array] } }
// }
// at /Users/md/github/huWaWi3-backend/src/resolvers/search.ts:79:59
// [GraphQLError [Object]: Unexpected end of input] {
//   locations: [ { line: 2, column: 3 } ],
//   path: [ 'search' ],
//   extensions: { code: 'INTERNAL_SERVER_ERROR', exception: { stacktrace: [Array] } }
// }
