// Created by Dr. Maximillian Dornseif 2021-09-97
// Copyright 2021 HUDORA

import { ICommandBarItemProps } from '@fluentui/react'
import { JSONSchema7 } from 'json-schema'
import React from 'react'
import { Route, useLocation } from 'wouter'

import { useSchema } from '../hooks/useSchema'
import { EntityPage, IEntityPageProps } from './EntityPage'
import { EntitySelect2 } from './EntitySelect'
import { IKindListePaginatedProps, KindListePaginated } from './KindListePaginated'

export interface IKindPageProps
  extends IKindListePaginatedProps,
    Omit<IEntityPageProps, 'listQuery' | 'nr' | 'commandBarItems'> {
  basePath: string
  componentPath: string
  suggestionQuery?: any
  suppressDefaultEntityPage?: boolean
  entityCommandBarItems?: ICommandBarItemProps[]
}

/** Rendert die Startseite für eine Kind
 * mit einer Liste der Entities und der
 * Möglichkeit eine Entity auszuwählen, um sie anzuzeigen.
 */
export function KindPage(props: IKindPageProps) {
  const { schema } = useSchema(props.schemaName)
  const newBasePath = `${props.basePath}/${props.componentPath}`
  return (
    <React.StrictMode>
      <section>
        <Route key="listPage" path={newBasePath}>
          {KindPageListe(props, newBasePath, schema)}
        </Route>
        <Route key="entityPage1" path={`${newBasePath}/:nr+`}>
          {(params: any) => {
            const nr = params.nr.split('/')[0]
            return (
              <EntityPage
                {...props}
                commandBarItems={props.entityCommandBarItems}
                nr={nr}
                basePath={newBasePath}
              >
                {props.children}
              </EntityPage>
            )
          }}
        </Route>
      </section>
    </React.StrictMode>
  )
}
function KindPageListe(props: IKindPageProps, newBasePath: string, schema: JSONSchema7) {
  const [, navigate] = useLocation()
  // const placeholder = (schema?.properties?.designator as JSONSchema7)?.title
  return (
    <>
      {props.suggestionQuery ? (
        <EntitySelect2
          onDesignatorSet={(nr) => {
            navigate(`${newBasePath}/${nr}`)
          }}
          schemaNames={[props.schemaName]}
        />
      ) : null}
      <KindListePaginated internalDesignatorLink={true} {...props} />
    </>
  )
}
