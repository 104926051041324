import { useQuery } from '@apollo/client'
import { ICommandBarItemProps } from '@fluentui/react'
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot'
import { useTitle } from 'fluentui-hooks'
import { EntityPageInner } from 'lib/components/EntityPageInner'
import { LadeChecker } from 'lib/components/UiChecker'
import React from 'react'
import { Route, useLocation, useRouter } from 'wouter'

import { EntitySubDisplayProps } from './EntitySubDisplay'

export interface IEntitySubPage {
  title: string
  path?: string
  component?: React.ReactElement
  iconName?: string
}

export interface IEntityPageProps extends EntitySubDisplayProps {
  basePath: string
  subPages?: IEntitySubPage[]
  children?: any
  suppressDefaultEntityPage?: boolean
  commandBarItems?: ICommandBarItemProps[]
}
/** Anzeige für "Entities",  mit Tabbar,
 * In der können SubEinheiten wie z.B. Lieferadressen eines Kunden,
 * angezeigt werden. Dazu dient <SubEntityDisplay>
 **/
export const EntityPage = (props: IEntityPageProps) => {
  const [location, navigate] = useLocation()
  const router = useRouter()
  const { loading, error, data } = useQuery(props.entityQuery, {
    variables: { nr: props.nr },
    skip: !props.nr,
    errorPolicy: 'all',
    pollInterval: 15 * 1000 * 60,
  })
  const basePath = `${props.basePath}/${props.nr}`
  const title =
    data?.entity?.name || data?.entity?.designator
      ? `${data?.entity?.name || ''} (${data?.entity?.designator})`
      : props.nr
  useTitle(title)

  let selectedKey = ''
  const pivotItems: React.ReactElement[] = [
    <PivotItem
      key={`item_Stammdaten`}
      itemKey={`item_Stammdaten`}
      headerText="Stammdaten"
      ariaLabel="Stammdaten"
      headerButtonProps={{ href: basePath }}
    />,
  ]

  const pivotPages: React.ReactElement[] = []
  for (const page of props.subPages || []) {
    const key = `pivot-${page.title}`
    const path = `${basePath}/${page.path}`
    const [match] = router.matcher(path, location)
    if (match) {
      selectedKey = key
    }
    pivotItems.push(
      <PivotItem
        key={key}
        itemKey={key}
        headerText={page.title}
        ariaLabel={page.title}
        headerButtonProps={{ href: path }}
        itemIcon={page.iconName}
      />
    )
    pivotPages.push(
      <Route key={key} path={path}>
        {React.cloneElement(page.component as any, {
          path: path,
          key: key,
          entity: data?.entity,
          nr: props.nr,
        })}
      </Route>
    )
  }

  const pivot = (
    <Pivot
      headersOnly
      selectedKey={selectedKey}
      onLinkClick={(item) => navigate(`${item?.props?.headerButtonProps?.href}`)}
    >
      {pivotItems}
    </Pivot>
  )

  if (!props.nr) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<!-- EntityPage hat keine nr ${JSON.stringify(props)}} -->`,
        }}
      />
    )
  }

  const dataError = !data?.entity
    ? new Error(`Keine Entity Daten für "${props.nr}" erhalten (${error || '?'}))`)
    : undefined

  if (loading && !data) {
    return <LadeChecker loading={loading} error={error} label={`Lade Daten für Entity ${props.nr} …`} />
  }

  return (
    <LadeChecker loading={false} error={dataError} label={`lade Daten für Entity ${props.nr} …`}>
      <>
        <h1 aria-label="EntityName">{title || 'Lade den Namen…'}</h1>

        {pivotPages.length > 0 ? pivot : null}

        <Route path={basePath} key={`item_Stammdaten`}>
          {!props.suppressDefaultEntityPage ? (
            <EntityPageInner
              anmerkungDisplay
              entity={data?.entity}
              schemaName={props.schemaName}
              mutation={props.editMutation}
              commandBarItems={props.commandBarItems}
            />
          ) : null}
          {React.Children.map(props.children, (child) =>
            React.cloneElement(child, {
              entity: data?.entity,
              nr: data?.entity?.designator,
            })
          )}
        </Route>
        {pivotPages}
      </>
    </LadeChecker>
  )
}
