// Mehrere der Standard-Datenbankobjekte (Kunde etc) in einem Panel neu anlegen oder bearbeiten
//
// Created by Dr. Maximillian Dornseif 2021-04-20
// Copyright 2021 Dr. Maximillian Dornseif

import { ErrorPolicy, useMutation, useQuery } from '@apollo/client'
import { IconButton } from '@fluentui/react/lib/Button'
import { ComboBox } from '@fluentui/react/lib/ComboBox'
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { Stack } from '@fluentui/react/lib/Stack'
import { parse as parseMoney } from '@munny/parser'
import * as Sentry from '@sentry/react'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import addMoreFormats from 'ajv-formats-draft2019'
import { assertIsObject, assertIsString } from 'assertate'
import { usePanel } from 'fluentui-hooks'
import { DocumentNode } from 'graphql'
import { JSONSchema7 } from 'json-schema'
import omitBy from 'lodash.omitby'
import { parse as parseCsv } from 'papaparse'
import React, { ReactElement, useState } from 'react'
import ReactJson from 'react-json-view'

import { IEntity, TListQueryOptions } from '../../types'
import { useSchema } from '../hooks/useSchema'
import { LadeChecker, getErrorMessageBar } from './UiChecker'

// Entity in einem Panel im Batch Anlegen/Bearbeiten

const ajv = new Ajv({ strict: false }) // options can be passed, e.g. {allErrors: true}
addFormats(ajv)
addMoreFormats(ajv)
ajv.addFormat('eurocent', /\d*/)
ajv.addFormat('eurocentlarge', /\d*/)
ajv.addFormat('currencycent', /\d*/)

interface IEntityBulkEditProps {
  schemaName: string
  listQuery: DocumentNode // GQL-Abfrage, um an die daten zu gelangen
  listQueryOptions?: TListQueryOptions // GQL-Optionen für die List-Query
  editMutation?: DocumentNode
  createMutation?: DocumentNode
  createTemplate?: IEntity
  keyField: string // anhand dieses Feldes werden alte und neue Daten zugeordnet
}
export function useBulkEditPanel(
  config: IEntityBulkEditProps
): [ICommandBarItemProps | null, ReactElement | null] {
  const [openPanel, panelProps, isPanelOpen] = usePanel('Daten im Stapel aktualisieren')

  if (!config.keyField) {
    return [null, null]
  }

  const commandBarItem: ICommandBarItemProps = {
    key: 'bulkEdit',
    text: 'Massen-Update',
    ariaLabel: 'Massen-Update',
    iconProps: {
      iconName: 'BulkUpload',
    },
    iconOnly: false,
    onClick: openPanel,
  }

  const panel = (
    <Panel {...panelProps} type={PanelType.extraLarge}>
      {isPanelOpen ? (
        <EntityBulkEdit
          schemaName={config.schemaName}
          keyField={config.keyField}
          listQuery={config.listQuery}
          // listQueryOptions={{
          //   variables: { nr: 'SC10001' },
          // }}
          createMutation={config.createMutation}
          editMutation={config.editMutation}
        />
      ) : (
        <>lade…</>
      )}
    </Panel>
  )

  return [commandBarItem, panel]
}

/** Mehrere Entities am Stück mit Daten aus einer Tabellenkalkulation ändern
 * */
export const EntityBulkEdit: React.FC<IEntityBulkEditProps> = (props) => {
  const { loading, error, schema } = useSchema(props.schemaName)

  if (loading || error) {
    return <LadeChecker loading={loading} error={error} label={`Lade Schema ${props.schemaName} …`} />
  }

  return <EntityBulkEditInnerMitSchema {...props} schema={schema} />
}

const EntityBulkEditInnerMitSchema: React.FC<IEntityBulkEditProps & { schema: JSONSchema7 }> = (props) => {
  assertIsString(props.keyField, 'keyField')
  assertIsString(props.schemaName, 'schemaName')
  assertIsObject(props.schema, 'schema')
  assertIsObject(
    props.schema.properties[props.keyField],
    'props.schema.properties[props.keyField]',
    `bulkEditKeyField  ${props.keyField} muss auf ein gültiges Schema-Feld zeigen`
  )
  assertIsObject(props.listQuery, 'listQuery')

  const [messageBar, setMessageBar] = useState(null)
  // Vom Nutzer eingegebenen CSV-Daten
  const [rawCsv, setRawCsv] = useState('')
  const [csvColumnNames, setCsvColumnNames] = useState({})
  const [csvErrors, setCsvErrors] = useState<string[]>([])
  const {
    loading: gqlLoading,
    error: gqlError,
    data: gqlData,
  } = useQuery(props.listQuery, props.listQueryOptions)

  if (gqlError) {
    return <LadeChecker loading={gqlLoading} error={gqlError} label={`Lade Daten …`} />
  }

  // Wir geben die Möglichkeit, alle Felder aus dem Schema zu befüllen,
  // die nicht readOnly ist.

  const schemaFelder = {
    [props.keyField]: props.schema.properties[props.keyField] as any,
    ...omitBy(props.schema.properties, (x) => x.readOnly),
  }

  // Parsen der bisher eingegebenen CSV Daten
  const {
    data: csvData,
    errors: newCsvErrors,
    meta,
  } = parseCsv<Record<string, boolean | null | string | Number | Date>>(rawCsv, {
    dynamicTyping: true,
    header: true,
    comments: '#',
    skipEmptyLines: 'greedy',
    delimitersToGuess: ['\t', '|', ';'],
  })

  // Errors in der GUI loggen - das ist etwas verzwickt, um re-renders zu vermeiden
  if (newCsvErrors && newCsvErrors.length > 0) {
    const newCsvErrorList = newCsvErrors.map((x) => x.message)
    if (JSON.stringify(csvErrors) !== JSON.stringify(newCsvErrorList)) {
      setCsvErrors(newCsvErrorList)
    }
  } else if (csvErrors.length > 0) {
    setCsvErrors([])
  }

  // Wir können jetzt für jedes Feld aus dem Schema
  // ein Feld aus dem CSV zuordnen
  // Die Optionen werden aus den Felder des CSV Headers erzeugt.
  const options = meta?.fields.map((x) => ({ key: x, text: x }))
  options.push({ key: '- leer -', text: '- leer -' })

  // Dreispaltige Benuzeroberfläche rendern:
  // * CSV Eingabe
  // * Zuordnung CSV - Schema
  // * Ergebnis mit Action-Auttons
  return (
    <div>
      <p>
        <b>Achtung!</b> Diese Funktionalität ist noch ungetestet und im Entwicklungsstadium. Überprüfen Sie{' '}
        <em>immer</em> duch "Reload" des Browsers, ob die geänderten Daten wie gewünscht im System angekommen
        sind.
      </p>

      {messageBar}

      <Stack horizontal tokens={{ childrenGap: '2em' }} style={{ width: '100%' }}>
        <Stack.Item>
          <h2>1. Rohdaten</h2>
          {rawCsv === '' ? (
            <p>
              Bitte Kopieren sie die Daten aus Ihrer Tabellenkalkulation direkt in das unten stehende Feld.
              Die erste Zeile sollte die Feldnamen beinhalten.
            </p>
          ) : (
            ''
          )}
          <p>
            Auf jeden Fall muss das Feld{' '}
            <b>
              <code>{props.keyField}</code>
            </b>
            / {(props.schema.properties?.[props.keyField] as Record<string, any>)?.title} enthalten sein. So
            werden die Zeilen der Datenbank zugeordnet.
          </p>
          <textarea
            aria-label="CSV-Eingabe"
            placeholder={_placeholderErmitteln(schemaFelder)}
            style={{ width: '100%', minWidth: '25em' }}
            rows={10}
            onChange={(event) => setRawCsv(event.target.value)}
          />
          {csvData ? <p title="Zeilenzahl">{csvData?.length} Zeilen</p> : null}
          {csvData?.length > 0 && csvErrors.length > 0 ? (
            <div title="Eingabe-Fehler">
              <p>Probleme mit den Eingabe-Daten:</p>
              <ul>
                {csvErrors.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {meta?.fields && meta?.fields.length > 0 ? (
            <div title="Spalten">
              <p>Erkannte Spalten:</p>
              <ul>
                {meta?.fields.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </Stack.Item>
        <Stack.Item>
          <h2>2. Konfiguration</h2>
          <ConfigArea
            csvColumnNames={csvColumnNames}
            setFieldConfig={setCsvColumnNames}
            schemaFelder={schemaFelder}
            options={options}
          />
        </Stack.Item>
        <Stack.Item>
          <h2>3. Bearbeiten</h2>
          <UploadData
            schemaFelder={schemaFelder}
            schema={props.schema}
            entities={gqlData?.nodes?.edges?.map((x) => x.node)}
            csvData={csvData}
            csv2gql={csvColumnNames}
            setMessageBar={setMessageBar}
            listQuery={props.listQuery}
            listQueryOptions={props.listQueryOptions}
            createMutation={props.createMutation}
            editMutation={props.editMutation}
            keyField={props.keyField}
          />
        </Stack.Item>
      </Stack>
      <ReactJson
        name="Details"
        collapsed={true}
        displayObjectSize={false}
        src={{ options, csvColumnNames, csvData, rawCsv, meta, schemaFelder, gqlLoading, gqlData, gqlError }}
      />
    </div>
  )
}

/** Den Platzhalter für das Eingabefeld erstellen wir aus den Feldern im Schema,
 * die keine Default-Werte haben.
 * */
function _placeholderErmitteln(schemaFelder: any) {
  const placeholder1 = [] // Überschriften
  const placeholder2 = [] // Beispiel-Werte
  for (const feld2 of Object.values(schemaFelder)) {
    const feld = feld2 as JSONSchema7
    if (feld && feld.default === undefined) {
      placeholder1.push(feld.title)
      placeholder2.push(feld?.examples?.[0])
    }
  }
  const placeholder = `${placeholder1.join('\t')} \n${placeholder2.join('\t')}`
  return placeholder
}

/** Einstellung, welche CSV Spalten wie auf das Objekt gemappt werden sollen.
 *
 * Dabei geben wir für jedes benutzbare Schema Feld eine Auswahl an,
 * mit welchem CSV-Feld sie befült werden sollen.
 */
export function ConfigArea(props: {
  schemaFelder: JSONSchema7['properties']
  csvColumnNames: Record<string, any>
  setFieldConfig: React.Dispatch<React.SetStateAction<{}>>
  options: { key: string; text: string }[]
}) {
  return (
    <section title="Konfiguration">
      <h3>Spalten zuordnen</h3>
      <p>Für welches Datenbankfeld muss welche Spalte aus dem CSV herhalten?</p>
      {Object.entries(props.schemaFelder).map((x) => {
        const key: string = x[0]
        const field: JSONSchema7 = x[1] as JSONSchema7
        assertIsString(field?.title)
        if (!props.csvColumnNames[key]) {
          props.setFieldConfig((prevOptions) => {
            return { ...prevOptions, [key]: '- leer -' }
          })
        }
        return (
          <div key={key} title={field?.title}>
            <ComboBox
              selectedKey={props.csvColumnNames[key]}
              label={`DB ${field?.title}`}
              options={props.options}
              onChange={(_e, option) =>
                props.setFieldConfig((prevOptions) => {
                  return { ...prevOptions, [key]: option.key }
                })
              }
            />
          </div>
        )
      })}
    </section>
  )
}

/** Anzeige aller neuen und geänderten Datensätze basierend auf den Eingabedaten
 * Mit Buttons, um den Datenbestand anzupassen.
 */
function UploadData(props: {
  schemaFelder: JSONSchema7['properties']
  schema: JSONSchema7
  entities: Record<string, any>[] // IEntity[]
  csvData: Record<string, any>[]
  csv2gql: Record<string, string>
  setMessageBar: React.Dispatch<React.SetStateAction<any>>
  listQuery: DocumentNode
  listQueryOptions: TListQueryOptions
  createMutation: DocumentNode
  editMutation: DocumentNode
  keyField: string
}) {
  if (!props.schemaFelder) {
    return null
  }
  const entities = {}
  if (props.entities) {
    for (const entity of props.entities) {
      entities[entity?.[props.keyField]] = entity
    }
  }
  return (
    <section title="DB-Ändern">
      <table className="hd-mytable striped">
        {/* Spalten - Überschriften */}
        <thead>
          <tr>
            {/* 1. Spalte ist immer der Designator */}
            <th>№</th>
            {Object.entries(props.schemaFelder).map((x) => {
              const [key, field] = x
              return props.csv2gql[key] === '- leer -' ? null : (
                <th key={key + (field as JSONSchema7)?.title}>{(field as JSONSchema7)?.title}</th>
              )
            })}
            <td />
          </tr>
        </thead>
        {/* Die eigentlichen Daten */}
        <tbody>
          {props.csvData.map((x) => (
            <ParsedRow
              key={JSON.stringify(x)}
              schemaFelder={props.schemaFelder}
              schema={props.schema}
              entities={entities}
              recordData={x}
              csvKeyNames={props.csv2gql}
              setMessageBar={props.setMessageBar}
              listQuery={props.listQuery}
              listQueryOptions={props.listQueryOptions}
              createMutation={props.createMutation}
              editMutation={props.editMutation}
              keyField={props.keyField}
            />
          ))}
        </tbody>
      </table>
      <ReactJson
        name="Details"
        collapsed={true}
        displayObjectSize={false}
        src={{
          props,
          entities,
        }}
      />
    </section>
  )
}

interface IEditField {
  id: string
  schema: JSONSchema7
  keyInCsv: any
  data: any
  oldData?: any
  error?: string
}

/** Stelle eine Zeile mit GQL vs neuen Daten dar und biete die Möglichkeit diese hoch zu laden.
 */
function ParsedRow(props: {
  schemaFelder: JSONSchema7['properties']
  schema: JSONSchema7
  entities: Record<string, any>
  recordData: Record<string, boolean | null | string | Number | Date>
  csvKeyNames: Record<string, string>
  setMessageBar: React.Dispatch<React.SetStateAction<any>>
  listQuery: DocumentNode
  listQueryOptions: TListQueryOptions
  createMutation: DocumentNode
  editMutation: DocumentNode
  keyField: string
}) {
  if (!props.schemaFelder) {
    return null // es ist noch nichts konfiguriert
  }

  let gqlKey
  // Wert des PrimaryKey im CSV ermitteln
  // mit dem werdend ei Daten mit den GraphQL Daten syncronisiert
  Object.entries(props.schemaFelder).map((x) => {
    const [key] = x
    const data = props.recordData?.[props?.csvKeyNames[key]]
    if (key === props.keyField) {
      gqlKey = data
    }
    return null
  })
  const gqlRecord = props.entities[gqlKey]

  // Daten aufbereiten und prüfen
  const newRow: IEditField[] = Object.entries(props.schemaFelder).map((keyUndSchemaFeld) =>
    _felderAufbereiten(keyUndSchemaFeld, props)
  )

  // TODO: Was passiert hier?
  // newRow.map((x) => {
  //   if (x?.data && gqlRecord && gqlRecord?.[x?.id] == x?.data) {
  //     return true
  //   }
  //   if ((gqlRecord?.[x?.id] ?? null) === (x?.data ?? null)) {
  //     return true
  //   }
  //   return false
  // })
  // TODO: Fehler anzeigen

  return (
    <tr>
      <th>{gqlRecord?.[props.keyField]}</th>
      {newRow.map((x) =>
        x.keyInCsv === '- leer -' ? null : (
          <td key={x?.id}>
            {x.error ? (
              x.error
            ) : (
              <ParsedItem
                id={x?.id}
                schema={x?.schema}
                keyInCsv={x?.keyInCsv}
                newData={x?.data ?? null}
                oldData={gqlRecord?.[x?.id] ?? null}
              />
            )}
          </td>
        )
      )}
      <td>
        {props.createMutation ? (
          <AddGadget
            gqlRecord={gqlRecord}
            newRow={newRow}
            listQuery={props.listQuery}
            listQueryOptions={props.listQueryOptions}
            setMessageBar={props.setMessageBar}
            mutation={props.createMutation}
          />
        ) : null}
        {props.editMutation ? (
          <UpdateGadget
            gqlRecord={gqlRecord}
            newRow={newRow.filter((x) => x.keyInCsv !== '- leer -')}
            listQuery={props.listQuery}
            listQueryOptions={props.listQueryOptions}
            setMessageBar={props.setMessageBar}
            mutation={props.editMutation}
          />
        ) : null}
      </td>
    </tr>
  )
}

function _felderAufbereiten(
  keyUndSchemaFeld,
  props: {
    recordData: Record<string, any>
    csvKeyNames: Record<string, any>
  }
): IEditField {
  const [key, field] = keyUndSchemaFeld
  const schema: JSONSchema7 = field
  const keyInCsv = props?.csvKeyNames[key]
  let data = props.recordData?.[keyInCsv]
  let error

  // TODO: Parsen auslagern
  if (schema?.format === 'eurocent') {
    // irgendwas in cent umrechnen
    try {
      const parsed = parseMoney(data)
      if (parsed) {
        if (parsed.precision === 2) {
          data = parsed.amount
        } else if (parsed.precision === 1) {
          data = parsed.amount * 10
        } else if (parsed.precision === 0) {
          data = parsed.amount * 100
        }
        data = Math.trunc(data)
      }
    } catch (e) {
      error = 'Zahl kann nicht interpretiert werden'
    }
  }
  if (schema?.type === 'string' && data !== undefined) {
    data = `${data}`
  }

  if (data === undefined && !!schema?.default) {
    data = schema.default
  }

  const validate = ajv.compile(schema || false)
  const valid = validate(data)
  if (!valid && validate.errors?.[0]?.message !== 'must be string') {
    error = `${validate.errors?.[0]?.message}`
  }
  // TODO: JSON Schema checken
  return {
    id: `${key}`,
    schema,
    keyInCsv,
    error,
    data,
    oldData: undefined,
  }
}

/** Eine Tabellenzelle: ein Datum mit Diff darstellen.
 *
 * Also die Daten aus dem CSV gegen die Daten aus der Datenbank/GraphQL anzeigen.
 */
function ParsedItem(props: {
  id: string
  schema: JSONSchema7
  keyInCsv: string
  newData: any
  oldData: any
}) {
  if (props.newData === undefined || props.newData === null) {
    return <>-</>
  }
  if (props.newData === props.oldData) {
    return <>{`${props.newData}`}</>
  }

  return (
    <>
      <span style={{ textDecoration: 'line-through' }}>{`${props.oldData || '- leer -'}`}</span>
      {` ${props.newData}`}
    </>
  )
}

/** Einen neuen Datensatz zufügen
 * */
function AddGadget(props: {
  gqlRecord: any
  newRow: any
  setMessageBar: React.Dispatch<React.SetStateAction<any>>
  listQuery: DocumentNode
  listQueryOptions: TListQueryOptions
  mutation: DocumentNode
}) {
  const [mutateEntity, { loading: saving }] = useMutation(props.mutation, _mutationParameters(props))

  // Daten per GraphQL Mutation an den Server senden.
  const uebertragen = async () => {
    const variables = {
      nr: 'SC10001',
      input: {
        ...Object.fromEntries(props.newRow.map((x) => [x.id, x.data])),
        kundennr: 'SC10001',
      },
    }
    const result = await mutateEntity({ variables })
    // TODO: result verwenden
    if (result?.errors?.map) {
      props.setMessageBar(getErrorMessageBar(result))
    } else if (result === undefined) {
      props.setMessageBar(
        getErrorMessageBar('Schwieriger Server Fehler - vermutlich ist das Ding nicht erreichbar.')
      )
    } else {
      const newEntity = result.data.entity
      const bar = (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true} truncated>
          Der Datensatz wurde erfolgreich zugefügt: <code>{newEntity?.designator}</code>:{' '}
          {JSON.stringify(newEntity)}
        </MessageBar>
      )
      props.setMessageBar(bar)
    }
  }
  if (props.gqlRecord) {
    return null
  }
  if (props.newRow.map((x) => x.data)?.every((x) => x === undefined)) {
    return null
  }

  return (
    <>
      {saving ? (
        <Spinner labelPosition="right" size={SpinnerSize.small} />
      ) : (
        <IconButton
          iconProps={{ iconName: 'CircleAdditionSolid' }}
          title="Anlegen"
          ariaLabel="Anlegen"
          onClick={uebertragen}
        />
      )}
    </>
  )
}

function UpdateGadget(props: {
  gqlRecord: any
  newRow: any
  setMessageBar: React.Dispatch<React.SetStateAction<any>>
  listQuery: DocumentNode
  listQueryOptions: TListQueryOptions
  mutation: DocumentNode
}) {
  // GraphQL Mutation
  const [mutateEntity, { loading: saving }] = useMutation(props.mutation, _mutationParameters(props))

  // Daten per GraphQL Mutation an den Server senden.
  const uebertragen = async () => {
    const variables = {
      designator: props?.gqlRecord?.designator,
      input: {
        ...Object.fromEntries(
          props.newRow.filter((x) => x.keyInCsv !== '- leer -').map((x) => [x.id, x.data])
        ),
        designator: props?.gqlRecord?.designator,
      },
    }
    const result = await mutateEntity({ variables })
    if (result?.errors?.map) {
      props.setMessageBar(getErrorMessageBar(result))
    } else if (result === undefined) {
      props.setMessageBar(
        getErrorMessageBar('Schwieriger Server Fehler - vermutlich ist das Ding nicht erreichbar.')
      )
    } else {
      const bar = (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true} truncated>
          Der Datensatz wurde erfolgreich aktualisiert: <code>{props?.gqlRecord?.designator}</code>
        </MessageBar>
      )
      props.setMessageBar(bar)
    }
  }
  if (!props.gqlRecord) {
    // Updaten geht ja gar nicht, wenn wir keinen zugehörigen Datensatz haben.
    // Zufügen schon, aber das ist nicht unsere Aufgabe
    return null
  }

  // Komplett leere Datensätze bringen uns auch nicht weiter
  if (_datensatzLeer(props.newRow)) {
    return null
  }
  const changed = props.newRow
    .map((x) => (x.data ?? null) !== (props.gqlRecord?.[x.id] ?? null))
    .some((x) => x === true)

  if (!changed) {
    // Wenn nichts geändert wurde, braucht man auch nichts upzudaten.
    return null
  }

  return (
    <>
      {saving ? (
        <Spinner labelPosition="right" size={SpinnerSize.small} />
      ) : (
        <IconButton
          style={{
            height: '1em',
          }}
          iconProps={{ iconName: 'SyncToPC' }}
          title="Updaten"
          ariaLabel="Updaten"
          onClick={uebertragen}
        />
      )}
    </>
  )
}

function _datensatzLeer(d): boolean {
  return d.map((x) => x.data)?.every((x) => x === undefined)
}

/** Defaults für die Mutation
 */
function _mutationParameters(props: {
  setMessageBar: React.Dispatch<React.SetStateAction<any>>
  listQuery: DocumentNode
  listQueryOptions: TListQueryOptions
}) {
  return {
    errorPolicy: 'all' as ErrorPolicy,
    onCompleted: (x) => {
      console.log(x)
    },
    onError: (e) => {
      Sentry.captureException(e)
      props.setMessageBar(getErrorMessageBar(e))
    },
    // update list page
    // refetchQueries: [{ ...props.listQueryOptions, query: props.listQuery }],
  }
}
