import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import semver from 'semver'

const queryClient = new QueryClient()

export function VersionChecker() {
  return (
    <QueryClientProvider client={queryClient}>
      <VersionCheckerInner />
    </QueryClientProvider>
  )
}
function VersionCheckerInner() {
  const { data } = useQuery('repoData', () => fetch('/meta.json').then((res) => res.json()), {
    retry: true,
    refetchInterval: 600 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })
  if (data?.version !== undefined) {
    try {
      if (semver.lt(`${process.env.REACT_APP_VERSION}`, `${data?.version}`)) {
        const style: React.CSSProperties = {
          backgroundColor: 'rgb(255, 244, 206)',
          padding: '1em',
          width: '100%',
          marginBottom: '1em',
        }

        return (
          <div style={style}>
            Es ist eine neuere Version {data?.version} der Applikation verfügbar. Sie nutzen Version{' '}
            {process.env.REACT_APP_VERSION} von {process.env.REACT_APP_NAME}. Klicken sie{' '}
            <a href={`/doc/c/frontend/?v=${data?.version}`}>hier</a>, um die neue Version zu starten. Oder
            drücken Sie einfach <code>⌘+R</code> auf dem Mac beziehungsweise <code>F5</code> auf Windows.
          </div>
        )
      }
    } catch (e) {
      console.log('SemVer can not be parsed:', e)
    }
  }
  return null
}
