// Komponente, um ein Objekt (zB Gruppe) auszuwählen.
// Created by Dr. Maximillian Dornseif 2020-08-28
// Copyright 2020, 2021 Maximillian Dornseif

import { gql, useLazyQuery } from '@apollo/client'
import { useId } from '@fluentui/react-hooks'
import { BasePicker, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers'
import React, { useCallback, useState } from 'react'

import { useSchemata } from '../hooks/useSchema'
import { fuzzaldrinHighlight, fuzzaldrinSort } from '../util/fuzzaldrin-react'

export interface IQueryResultItem {
  id: string
  designator: string
  title: string
  score: number
}

export function EntitySelect2(props: {
  schemaNames: string[]
  onDesignatorSet: (string) => void
  label?: string | undefined
}) {
  const { schemata } = useSchemata(props.schemaNames)
  const [term, setTerm] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [refetch] = useLazyQuery(SEARCH2_QUERY)
  const pickerId = useId('entity-select-picker')

  /** Wird von der FluentUI BasePicker Komponente augerufen, um die Vorschläge zu laden
   * */
  const resolveSuggestions = useCallback(
    async (filterText: string, itemList) => {
      if (filterText !== term) {
        setTerm(filterText)
      }
      if (filterText.length > 2) {
        const searchQueryResult = await refetch({
          variables: { term: filterText, schemata: props.schemaNames },
        })
        return filterText ? fuzzaldrinSort(filterText, searchQueryResult?.data?.search) : []
      }
      return []
    },
    [props.schemaNames, schemata]
  )

  /** Wird von der FluentUI BasePicker Komponente aufgerufen, einen Vorschlag anzuzeigen
   * */
  const renderSuggestionsItem = useCallback(
    (props) => <div style={ITEM_STYLE}>{fuzzaldrinHighlight(props.title, term)}</div>,
    [term]
  )
  const onChange = useCallback(
    (items: Array<IQueryResultItem>) => {
      props?.onDesignatorSet(items[0]?.designator)
      setSelectedItems(items)
    },
    [props?.onDesignatorSet, setSelectedItems]
  )

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Vorschläge',
    noResultsFoundText: 'nichts gefunden',
    searchingText: 'suche …',
    loadingText: 'lade Vorschläge …',
    // searchForMoreText: 'mehr',
    // 'resultsMaximumNumber'
    // mostRecentlyUsedHeaderText: 'mostRecentlyUsedHeaderText',
    // 'className'
    // 'suggestionsClassName'
    // 'suggestionsItemClassName'
    // 'suggestionsAvailableAlertText' : 'suggestionsAvailableAlertText',
    // 'suggestionsContainerAriaLabel'
    // forceResolveText: 'forceResolveText',
    // 'showForceResolve': () =>true,
  }

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault()
      }}
    >
      {props.label ? <label htmlFor={pickerId}>{props.label}</label> : null}
      <BasePicker
        onRenderItem={onRenderItem}
        getTextFromItem={getTextFromItem}
        onChange={onChange}
        selectedItems={selectedItems}
        itemLimit={1} // wie viel Items dürfen ausgewählt werden
        onResolveSuggestions={resolveSuggestions} // Vorschläge vom Server abrufen
        resolveDelay={150} // wie lange Warten wir mit dem resolve
        onRenderSuggestionsItem={renderSuggestionsItem}
        inputProps={{
          placeholder: props.schemaNames.join(' / '),
          id: pickerId,
        }}
        pickerSuggestionsProps={pickerSuggestionsProps} // Konfiguration
        ariaLabel={props.schemaNames.join(', ')}
      />
    </form>
  )
}

/** Damit wird das ausgewählte Item in der Eingabezeile gerendert
 * */
const onRenderItem = (props) => (
  <div key={props?.item.designator} style={{ margin: '0 8px' }}>
    {props?.item.designator}
  </div>
)

/** Damit wird der Formular-Rückgabewert für das ausgewählte Item ermittelt
 * */
const getTextFromItem = (item): string => {
  return item.designator
}

const ITEM_STYLE: React.CSSProperties = {
  cursor: 'default',
  userSelect: 'none',
  display: 'flex',
  flexWrap: 'nowrap',
  maxWidth: 300,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 30,
  margin: '0 8px',
}

export const SEARCH2_QUERY = gql`
  query FulltextSearch($term: String!, $schemata: [String]) {
    search(term: $term, schemata: $schemata) {
      designator
      id
      istAktiv
      matches
      title
      url
      schema
    }
  }
`
