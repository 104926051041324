/*
 * hooksuseHotkey.tsx
 * basic idea from https://github.com/saravanan10393/letslearn/blob/main/hotkey/use.hotkey.js
 *
 * Created by Dr. Maximillian Dornseif 2021-11-29 in huWaWi3 24.6.0
 */

// import { install, uninstall } from '@github/hotkey'
import { assertIsString } from 'assertate'
import { useEffect, useRef } from 'react'

export function useHotkey(keys: string, title: string, options = { scope: '' }) {
  assertIsString(keys)
  assertIsString(title)
  const elementRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!elementRef.current || !(elementRef.current instanceof HTMLElement)) return () => {}

    if (options.scope) {
      elementRef.current.dataset.hotkeyScope = options.scope
    }

    // install(elementRef.current, keys)

    return () => {
      if (elementRef.current) {
        // uninstall(elementRef.current)
      }
    }
  }, [elementRef.current])

  return elementRef
}
