// Warenwirtschaftliche Funktionen
// Created 2021-03-24 by Dr. Maximillian Dornseif
// Copyright HUDORA 2021

import { ISidebarProps, WithSidebar } from 'fluentui-hooks'
import React from 'react'

import { EinkaufLieferantenApp } from './EinkaufLieferantenApp'
import { EinkaufBestellungApp } from 'Einkauf/EinkaufBestellungenApp'
import { EinkaufEinkaufspreisApp } from 'Einkauf/EinkaufPreise'

export const EinkaufApp = (props: any) => {
  const sidebar: ISidebarProps = {
    Bewegungen: {
      links: [
        { title: 'Bestellungen', path: '/einkauf/b', component: <EinkaufBestellungApp /> },
      ],
    },
    Stammdaten: {
      links: [
        { title: 'Preise', path: '/einkauf/ek', component: <EinkaufEinkaufspreisApp /> },
        { title: 'Lieferanten', path: '/einkauf/l', component: <EinkaufLieferantenApp /> },
      ],
    },
  }
  return <WithSidebar items={sidebar} />
}
